import { Button, Grid } from '@material-ui/core';
import React from 'react';

interface IProps {
  label: string;
  loading?: boolean;
  onClick?: any;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
}

export function CancelButton(props: IProps) {
  const { label, loading = false, onClick, disabled, size = 'medium' } = props;

  return (
    <Grid item>
      <Button onClick={onClick} disabled={loading || disabled} size={size}>
        {label}
      </Button>
    </Grid>
  );
}
