import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { useCreateTask } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { SiteContext } from 'components/Sites/SiteContext';
import { FormlyForm } from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import { getStaticTemplate } from 'formly/staticTemplates';
import React, { useContext, useState } from 'react';
import { AssigneeType, CreateTaskInputType, CreateTaskMutationVariables } from 'tillr-graphql';
import { UserProfileContext } from 'UserProfileContext';
import { generateUuid } from 'utils';

export function QuickTask(props: { onCreated?: () => void }) {
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createTask, { loading, data, reset, error }] = useCreateTask();
  const [taskId, setTaskId] = useState(generateUuid());
  const contextValue = useContext(UserProfileContext);
  const userId = contextValue?.getUserId();

  const [open, setOpen] = React.useState(false);

  if (open && data?.createTask) {
    const { onCreated } = props;
    if (onCreated) {
      onCreated();
    }
    setOpen(false);
    reset();
    setTaskId(generateUuid());
  }

  const handleSubmit = (model: IModel) => {
    const task: CreateTaskInputType = {
      id: taskId,
      name: model.name || '', // TODO
      description: model.description || '', // TODO
      dueDate: model.dueDate,
      assignees: [
        {
          // TODO
          assigneeId: userId || '00000000-0000-0000-0000-000000000000',
          assigneeType: AssigneeType.User,
        },
      ],
    };
    const variables: CreateTaskMutationVariables = { siteId, module, task };
    createTask({ variables });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const config = getStaticTemplate('quick-task');

  const formattedConfig = {
    fields: config.fields.map((field) => {
      if (field.key === 'dueDate') {
        Object.defineProperty(field.data, 'minDate', {
          value: new Date(),
          writable: true,
          enumerable: true,
        });
        return field;
      }
      return field;
    }),
  };

  return (
    <>
      <Tooltip title="Quick create new task">
        <Button
          variant="contained"
          aria-label="quick add"
          color="secondary"
          onClick={handleClickOpen}
        >
          <FlashOnIcon />
          Quick create
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Quick task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Creating a quick task will automatically assign this task to you.
          </DialogContentText>
          {error && <ErrorDisplay errorMessage={error.message} />}
          <FormlyForm
            formId={taskId}
            mode="create"
            config={formattedConfig}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitting={loading}
            modal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
