import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useGetForms, useGetFormTemplateWithFilterFields } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { DataTable } from 'components/DataViews/DataTable';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { FilterControl, FilterType, IFilter } from 'components/Filtering/FilterControl';
import { FilterFieldsControl } from 'components/Filtering/FilterFieldsControl';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { PaginationControl } from 'components/Pagination/PaginationControl';
import { PreviousNextControl } from 'components/Pagination/PreviousNextControl';
import { getSiteModuleRoute } from 'components/route-utils';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { ListControls } from 'components/Shared/ListControls';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  FormFilterBy,
  FormFilterFieldValuesType,
  FormListItemFragment,
  FormOrderBy,
  FormPaginationPropsType,
  PaginationOrder,
} from 'tillr-graphql';
import { AddFormButton } from './AddFormButton';
import { FormBreadcrumbs } from './FormBreadcrumbs';

const filterTypeMappings = new Map([
  [FormFilterBy.Name, FilterType.String],
  [FormFilterBy.CreatedDateTime, FilterType.Date],
]);

export function Forms(props: RouteComponentProps<{ formTemplateId: string }>) {
  const {
    match: {
      params: { formTemplateId },
    },
  } = props;

  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(FormOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[2],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[1],
  };

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;

  const getFormTemplateQueryState = useGetFormTemplateWithFilterFields({
    siteId,
    module,
    id: formTemplateId,
  });

  const getFormsQueryState = useGetForms({
    siteId,
    module,
    formTemplateId,
    paginationProps,
  });

  const handleChangeFilter = (nextFilter: IFilter<FormFilterBy>) => {
    getFormsQueryState.refetch({ filterProps: { filters: [nextFilter] } });
  };

  const handleChangeFilterFields = (nextFilters: Array<IFilter<FormFilterBy>>) => {
    getFormsQueryState.refetch({ filterProps: { filters: nextFilters } });
  };

  const handleChangePaginationProps = (nextPaginationProps: FormPaginationPropsType) => {
    getFormsQueryState.refetch({ paginationProps: nextPaginationProps });
  };

  const handleFetchPrevious = () => {
    getFormsQueryState.fetchMore({
      variables: { before: getFormsQueryState.data?.forms?.startCursor },
    });
  };

  const handleFetchNext = () => {
    getFormsQueryState.fetchMore({
      variables: { after: getFormsQueryState.data?.forms?.endCursor },
    });
  };

  const getDataToDisplay = (items: Array<FormListItemFragment>): Array<Record<string, any>> => {
    return items.map((item) => {
      const data: Record<string, any> = { id: item.id };

      if (item.filterFieldData.length > 0) {
        item.filterFieldData.forEach(({ name, value }) => {
          data[name] = value;
        });
        data.createdByUser = item.createdByUser;
      } else {
        data.name = item.name;
        data.createdByUser = item.createdByUser;
        data.createdDateTime = item.createdDateTime;
      }

      if (getFormTemplateQueryState.data?.formTemplate?.canSubmit) {
        data.isSubmitted = item.isSubmitted;
      }

      return data;
    });
  };

  const sortData = (
    data: Record<string, any>[],
    filterFields: Array<FormFilterFieldValuesType>,
  ): Record<string, any>[] => {
    let sortedData = data.slice();
    // Sort by last filter first so the final result is in order of first filter
    filterFields
      .slice()
      .reverse()
      .forEach((filterField) => {
        sortedData = sortedData.sort(
          (a, b) =>
            filterField.options.findIndex(({ name }) => name === a[filterField.label]) -
            filterField.options.findIndex(({ name }) => name === b[filterField.label]),
        );
      });
    return sortedData;
  };

  return (
    <>
      {getFormTemplateQueryState.error && <ErrorDisplay error={getFormTemplateQueryState.error} />}
      {getFormsQueryState.error && <ErrorDisplay error={getFormsQueryState.error} />}
      {getFormTemplateQueryState.data?.formTemplate && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">
                {getFormTemplateQueryState.data.formTemplate.name}
              </Typography>
              <FormBreadcrumbs title={getFormTemplateQueryState.data.formTemplate.name} />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          <ActionButtons permission={['Forms.Edit']}>
            <AddFormButton
              templateId={formTemplateId}
              templateName={getFormTemplateQueryState.data.formTemplate.name}
            />
          </ActionButtons>
          <Paper>
            <ListControls
              filterFields={
                <>
                  {!!getFormTemplateQueryState.data.formTemplate.filterFields?.length && (
                    <FilterFieldsControl
                      loading={getFormsQueryState.loading}
                      filterFields={getFormTemplateQueryState.data.formTemplate.filterFields!}
                      onChange={handleChangeFilterFields}
                    />
                  )}
                </>
              }
            >
              {!getFormTemplateQueryState.data.formTemplate.filterFields?.length && (
                <>
                  <PaginationControl<FormOrderBy>
                    initialProps={paginationProps}
                    options={paginationOptions}
                    onRefetch={handleChangePaginationProps}
                  />
                  <FilterControl<FormFilterBy>
                    filterByValues={Object.values(FormFilterBy)}
                    filterTypeMappings={filterTypeMappings}
                    onChange={handleChangeFilter}
                  />
                </>
              )}
              <PreviousNextControl
                hasPreviousPage={getFormsQueryState.data?.forms?.hasPreviousPage ?? false}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={getFormsQueryState.data?.forms?.hasNextPage ?? false}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
            <HorizontalLine margin={[1, 0, 1, 0]} />
            {getFormsQueryState.data?.forms ? (
              <DataTable
                data={sortData(
                  getDataToDisplay(getFormsQueryState.data.forms.items),
                  getFormTemplateQueryState.data.formTemplate.filterFields ?? [],
                )}
                itemRoute={getSiteModuleRoute(siteId, module, 'forms/{id}')}
              />
            ) : (
              <Box p={2}>
                <ActivityIndicator />
              </Box>
            )}
            <ListControls>
              <PreviousNextControl
                hasPreviousPage={getFormsQueryState.data?.forms?.hasPreviousPage ?? false}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={getFormsQueryState.data?.forms?.hasNextPage ?? false}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
          </Paper>
        </>
      )}
    </>
  );
}
