import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React, { ReactNode } from 'react';
import { IAppTheme } from 'themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) =>
  createStyles({
    warning: {
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: 1.43,
      backgroundColor: 'rgb(255, 244, 229)',
      display: 'flex',
      padding: '6px 16px',
      color: 'rgb(102, 60, 0)',
      margin: theme.spacing(0, 0, 2, 0),
      '& .icon': {
        color: 'rgb(255, 152, 0)',
        marginRight: '12px',
        padding: '7px 0px',
        display: 'flex',
        fontSize: '22px',
        opacity: 0.9,
      },
      '& .content': {
        padding: '8px 0px',
        minWidth: '0px',
        overflow: 'auto',
      },
      '& h5': {
        margin: '-2px 0px 0.35em',
        fontSize: '13px',
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },
    network: {
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: 1.43,
      backgroundColor: theme?.offlineMessage?.backgroundColor,
      display: 'flex',
      padding: '6px 16px',
      color: theme?.offlineMessage?.color,
      margin: theme.spacing(0, 0, 2, 0),
      '& .icon': {
        color: 'rgb(255, 152, 0)',
        marginRight: '12px',
        padding: '7px 0px',
        display: 'flex',
        fontSize: '22px',
        opacity: 0.9,
      },
      '& .content': {
        padding: '8px 0px',
        minWidth: '0px',
        overflow: 'auto',
      },
      '& h5': {
        margin: '-2px 0px 0.35em',
        fontSize: '13px',
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },
    error: {
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: 1.43,
      backgroundColor: 'rgb(253, 237, 237)',
      display: 'flex',
      padding: '6px 16px',
      color: 'rgb(95, 33, 32)',
      margin: theme.spacing(0, 0, 2, 0),
      '& .icon': {
        color: 'rgb(239, 83, 80)',
        marginRight: '12px',
        padding: '7px 0px',
        display: 'flex',
        fontSize: '22px',
        opacity: 0.9,
      },
      '& .content': {
        padding: '8px 0px',
        minWidth: '0px',
        overflow: 'auto',
      },
      '& h5': {
        margin: '-2px 0px 0.35em',
        fontSize: '13px',
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },
    info: {
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: 1.43,
      backgroundColor: 'rgb(229, 246, 253)',
      display: 'flex',
      padding: '6px 16px',
      color: 'rgb(1, 67, 97)',
      margin: theme.spacing(0, 0, 2, 0),
      '& .icon': {
        color: 'rgb(3, 169, 244)',
        marginRight: '12px',
        padding: '7px 0px',
        display: 'flex',
        fontSize: '22px',
        opacity: 0.9,
      },
      '& .content': {
        padding: '8px 0px',
        minWidth: '0px',
        overflow: 'auto',
      },
      '& h5': {
        margin: '-2px 0px 0.35em',
        fontSize: '13px',
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },
    btw: {
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 400,
      fontSize: '0.8125rem',
      lineHeight: 1.43,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      padding: '6px 16px',
      color: theme.palette.text.primary,
      margin: theme.spacing(3, 0),
      '& .icon': {
        color: theme.palette.text.primary,
        marginRight: '12px',
        padding: '7px 0px',
        display: 'flex',
        fontSize: '22px',
        opacity: 0.9,
      },
      '& .content': {
        padding: '8px 0px',
        minWidth: '0px',
        overflow: 'auto',
      },
      '& h5': {
        margin: '-2px 0px 0.35em',
        fontSize: '13px',
        lineHeight: 1.5,
        fontWeight: 500,
      },
    },
  }),
);

interface IProps {
  type: 'error' | 'warning' | 'network' | 'info' | 'btw';
  children?: ReactNode;
}

export function Alert(props: IProps) {
  const classes = useStyles();
  const { type, children } = props;
  return (
    <>
      {type === 'warning' && (
        <div className={classes.warning}>
          <div className="icon">
            <WarningIcon />
          </div>
          <div className="content">{children}</div>
        </div>
      )}
      {type === 'network' && (
        <div className={classes.network}>
          <div className="icon">
            <CloudOffIcon />
          </div>
          <div className="content">
            <Typography variant="h5" display="block">
              We are unable to reach the internet
            </Typography>
            <Typography variant="body1" display="block">
              You have lost your connection and this functionality in unavailable.
            </Typography>
          </div>
        </div>
      )}
      {type === 'info' && (
        <div className={classes.info}>
          <div className="icon">
            <InfoIcon />
          </div>
          <div className="content">{children}</div>
        </div>
      )}
      {type === 'error' && (
        <div className={classes.error}>
          <div className="icon">
            <ErrorIcon />
          </div>
          <div className="content">{children}</div>
        </div>
      )}
      {type === 'btw' && (
        <div className={classes.btw}>
          <div className="icon">
            <InfoIcon />
          </div>
          <div className="content">{children}</div>
        </div>
      )}
    </>
  );
}
