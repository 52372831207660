import Avatar from '@material-ui/core/Avatar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Alert } from 'components/Shared/Alert';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { deleteOfflineForm, useGetOfflineForms } from 'db';
import React, { useContext, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { UserProfileContext } from 'UserProfileContext';
import { formatAsFriendlyDate } from 'utils';

export function OfflineForms(props: any) {
  const userProfile = useContext(UserProfileContext)!;
  const data = useGetOfflineForms(userProfile);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [initialDialogOpen, setInitialDialogOpen] = useState(() => {
    // const { location: { state: { create } } } = props;

    const { location } = props;
    const { state } = location;

    if (state && state.create && state.create === true) {
      return true;
    }
    return false;

    // if (typeof state.create !== 'undefined' && state.create === true) {
    //   return true;
    // }
    // return false;
    // return !!(create && create === true);
  });
  let loading = true;
  const history = useHistory();

  if (data) {
    loading = false;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { formId } = event.currentTarget.dataset;
    if (formId) {
      history.push(`/offline-forms/${formId}`);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = (id: string) => {
    deleteOfflineForm(userProfile, id);
    handleCloseDeleteDialog();
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseInitialDialog = () => {
    setInitialDialogOpen(false);
  };

  return (
    <>
      {!loading && data && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">
                Offline Forms
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" component={RouterLink} to="/">
                  Dashboard
                </Link>
                <Typography color="textPrimary">Offline Forms</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          {data.length > 0 ? (
            <>
              <Alert type="info">
                <Typography variant="body1" display="block">
                  The following offline forms are saved to this device. It is strongly recommended
                  that you save these forms to the
                  {` ${userProfile.tenantName} system as soon as you have a connection to the internet. `}
                </Typography>
              </Alert>
              <Paper>
                <HorizontalLine margin={[1, 0, 1, 0]} />
                <TableContainer>
                  <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Saved</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((x: any) => (
                        <TableRow key={x.id} hover>
                          <TableCell
                            className="user-clickable"
                            data-form-id={x.id}
                            onClick={handleClick}
                          >
                            <div className="like-list-item-avatar">
                              <Avatar>
                                <ListAltIcon />
                              </Avatar>
                              <div className="like-list-item-avatar__text">
                                <Typography variant="body1" noWrap display="block">
                                  {x.name}
                                </Typography>
                                <Typography variant="body2" noWrap display="block">
                                  {x.template}
                                </Typography>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            className="user-clickable"
                            data-form-id={x.id}
                            onClick={handleClick}
                          >
                            {formatAsFriendlyDate(new Date(x.createdDateTime))}
                          </TableCell>
                          <TableCell className="user-clickable" data-form-id={x.id}>
                            <Tooltip title="Delete this form forever, and remove it form from this device">
                              <IconButton
                                color="primary"
                                aria-label="Delete from device"
                                component="span"
                                onClick={handleOpenDeleteDialog}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                            <Dialog
                              fullWidth
                              maxWidth="xs"
                              open={deleteDialogOpen}
                              onClose={handleCloseDeleteDialog}
                              aria-labelledby="delete-dialog-title"
                            >
                              <DialogTitle id="delete-dialog-title">
                                Delete offline form
                              </DialogTitle>
                              <DialogContent>
                                <Typography variant="body1" display="block">
                                  Are you certain you want to delete this offline form forever? Once
                                  deleted, you won&apros;t be able to retrieve it.
                                </Typography>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="primary">
                                  Cancel
                                </Button>
                                <Button onClick={() => handleDelete(x.id)} color="primary">
                                  Yes, delete this form
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <Dialog
                fullWidth
                maxWidth="xs"
                open={initialDialogOpen}
                onClose={handleCloseInitialDialog}
                aria-labelledby="initial-dialog-title"
              >
                <DialogTitle id="Initial-dialog-title">Form saved on your device</DialogTitle>
                <DialogContent>
                  <Typography variant="body1" display="block">
                    It looks like you lost your connection when saving your form. Don&apos;t worry,
                    we saved it to your device.
                    {' We recommend that you save it to the '}
                    {`${userProfile.tenantName} system as soon as you have a connection to the internet.`}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={handleCloseInitialDialog}>
                    Understood
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <Alert type="info">
              <Typography variant="body1" display="block">
                You currently have no offline forms saved to this device. Offline forms are only
                available on the device on which they were created.
              </Typography>
            </Alert>
          )}
        </>
      )}
    </>
  );
}
