import React from 'react';

interface IContext {
  siteId: number;
  setSiteId: (siteId: number) => void;
}

const SiteContext = React.createContext<IContext | undefined>(undefined);

export { SiteContext };
