import { Box } from '@material-ui/core';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import React, { useContext, useEffect, useState } from 'react';
import { UserManagerContext } from 'UserManagerContext';

export function Logout() {
  const userManager = useContext(UserManagerContext)!;

  const [error, setError] = useState<string>();

  useEffect(() => {
    userManager.signoutRedirect().catch((e: Error) => {
      setError(`Error: ${e.message}. Please refresh to try again.`);
    });
  }, [userManager]);

  if (error) {
    return <ErrorDisplay errorMessage={error} />;
  }

  return (
    <>
      <h3>One moment please</h3>
      <Box display="flex" alignItems="center" flexDirection="row">
        <p>Logging you out...</p>
        <Box ml={1}>
          <ActivityIndicator size="small" />
        </Box>
      </Box>
    </>
  );
}
