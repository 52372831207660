import { ActivityIndicator } from 'components/ActivityIndicator';
import React, { lazy, Suspense } from 'react';

const Home = lazy(() =>
  import(/* webpackChunkName: "hq" */ './Home').then((module) => ({ default: module.Home })),
);

export function WebpackChunkWrapper() {
  return (
    <Suspense fallback={<ActivityIndicator />}>
      <Home />
    </Suspense>
  );
}
