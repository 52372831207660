import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import { withComponentPropsMemo } from 'formly/withComponentPropsMemo';
import React, { useEffect } from 'react';
import { HelpText } from './shared/HelpText';
import { FieldValidationMessage, validationStyles } from './shared/Validation';

const ScoredCheckboxes = withComponentPropsMemo(function ScoredCheckboxes(props: IComponentProps) {
  const classes = validationStyles();
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    getValues,
    clearErrors,
    setError,
  } = props;
  let valueArray = (modelValue.model as Array<number | null>) || [];

  const customTrigger = () => {
    const values = getValues();

    const error = () => {
      setError(field.key, {
        type: 'manual',
        message: 'You must select at least one option.',
      });
    };

    if (field.key && field.templateOptions?.required) {
      if (!values[field.key] || !values[field.key].length) {
        error();
        return;
      }
      const numericValues = values[field.key].filter((v: number | null) => v !== null);
      if (!numericValues.length) {
        error();
        return;
      }
    }

    clearErrors(field.key!);
  };

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, valueArray);
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  if (!field.templateOptions?.options?.length) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: ScoredCheckboxes without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!field.key) return;
    const { checked, value, alt } = event.currentTarget;
    const index = Number(alt);
    valueArray = valueArray.map((option, i) => {
      if (i === index) {
        return checked ? Number(value) : null;
      }
      return option;
    });
    const total = valueArray.map((x) => x || 0).reduce((a, b) => a + b);
    onValueUpdate(field.key, { model: valueArray, total });
    setValue(field.key, valueArray);
    customTrigger();
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl component="fieldset" disabled={readonly} error={!!errors[field.key!]}>
          <FormLabel component="legend">
            {field.templateOptions?.label}
            {!readonly && field.templateOptions?.required && (
              <span className={classes.asterisk}>*</span>
            )}
          </FormLabel>
          <FormGroup>
            {field.templateOptions.options.map((option, i) => {
              const htmlId = `${field.key}_${i}`;
              return (
                <FormControlLabel
                  key={htmlId}
                  checked={valueArray[i] != null}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      id={htmlId}
                      value={option.value}
                      // Won't accept data attribute :/
                      inputProps={{ alt: `${i}` }}
                    />
                  }
                  label={option.name}
                />
              );
            })}
          </FormGroup>
          {errors[field.key!] && (
            <FieldValidationMessage className="non-input" message={errors[field.key!].message} />
          )}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
});

export { ScoredCheckboxes };
