import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useUpdateSite } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { FormlyForm } from 'formly/FormlyForm';
import { getStaticTemplate } from 'formly/staticTemplates';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { SiteAdminType, UpdateSiteMutationVariables } from 'tillr-graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
  }),
);

interface IProps {
  site: SiteAdminType;
  onCloseMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function EditSiteName(props: IProps) {
  const classes = useStyles();
  const { site, onCloseMenu } = props;
  const [open, setOpen] = useState(false);

  const [updateSite, { loading, data, error }] = useUpdateSite();

  if (data?.updateSite) {
    return <Redirect to="/admin/sites" />;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (model: { name: string }) => {
    if (site.name !== model.name) {
      const variables: UpdateSiteMutationVariables = {
        site: {
          id: site.id,
          name: model.name,
          externalId: site.externalId,
          type: site.type,
          parentSiteId: site.parentSiteId,
        },
      };
      updateSite({ variables });
    } else {
      handleClose();
    }
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen(true);
    onCloseMenu(event);
  };

  const config = getStaticTemplate('edit-site-name');

  const model = {
    name: site.name,
  };

  return (
    <>
      <Button
        aria-label="quick add"
        onClick={handleClickOpen}
        classes={{
          root: classes.root,
        }}
      >
        Edit site name
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Edit site name</DialogTitle>
        <DialogContent>
          {error && <ErrorDisplay error={error} />}
          <FormlyForm
            config={config}
            onSubmit={handleSubmit}
            onCancel={handleClose}
            submitting={loading}
            model={model}
            modal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
