import { Divider, Grid, Typography } from '@material-ui/core';
import { useGetTenantReports } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { AdminBreadcrumbs } from 'components/Admin/AdminBreadcrumbs';
import { AdminNavigation } from 'components/Admin/AdminNavigation';
import { DataTable } from 'components/DataViews/DataTable';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { TitlePrefix } from 'components/Shared/TitlePrefix';
import React from 'react';

export function Reports() {
  const { loading, error, data } = useGetTenantReports();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Reports
            </>
          </Typography>
          <AdminBreadcrumbs title="Reports" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <Divider className="divider" />

      <AdminNavigation path="/admin/reports" />

      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.tenantReports && <DataTable data={data.tenantReports} itemRoute="/reports/{id}" />}
    </>
  );
}
