import { Button, Chip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    buttonRoot: {
      textDecoration: 'underline',
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
  }),
);

interface ISite {
  id: number;
  name: string;
}

interface IProps {
  sites: ISite[];
}

export function SitesSummary(props: IProps) {
  const { sites } = props;
  const classes = useStyles();

  return (
    <>
      {!sites.length && <Typography variant="body1">Not allocated to any site(s)</Typography>}
      <div className={classes.root}>
        {sites.length &&
          sites
            .slice(0, 2)
            .map((site) => <Chip key={site.id} icon={<AccountTreeIcon />} label={site.name} />)}
        {sites.length > 2 && (
          <Typography variant="body1">
            <Button
              classes={{
                root: classes.buttonRoot,
              }}
            >
              +{` ${sites.length - 2} `}
              more
            </Button>
          </Typography>
        )}
      </div>
    </>
  );
}
