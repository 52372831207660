import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NetworkStatusContext } from 'NetworkStatusContext';
import React, { useContext } from 'react';
import { IAppTheme } from 'themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) =>
  createStyles({
    offline: {
      backgroundColor: theme?.offlineMessage?.backgroundColor,
      color: theme?.offlineMessage?.color,
    },
  }),
);

export function OfflineMessage() {
  const classes = useStyles();
  const networkStatusContext = useContext(NetworkStatusContext);

  return (
    <div
      className={`${classes.offline} offline-ui ${
        networkStatusContext ? '' : 'offline-ui-down'
      } offline-ui-waiting`}
    >
      <div className="offline-ui-content">Network connection has been lost.</div>
    </div>
  );
}
