import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useCreateInvitation } from 'apollo-hooks';
import { AdminBreadcrumbs } from 'components/Admin/AdminBreadcrumbs';
import { AdminNavigation } from 'components/Admin/AdminNavigation';
import { SiteMultiCheckboxes } from 'components/Admin/Sites/SiteMultiCheckboxes';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { LinkButton } from 'components/Shared/LinkButton';
import { MandatoryFieldMessage } from 'components/Shared/MandatoryFieldMessage';
import { ProgressButton } from 'components/Shared/ProgressButton';
import { TitlePrefix } from 'components/Shared/TitlePrefix';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CreateInvitationInputType, CreateInvitationMutationVariables } from 'tillr-graphql';
import { UserProfileContext } from 'UserProfileContext';
import { RolePickList } from './RolePickList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navMargin: {
      margin: theme.spacing(2, 0),
    },
  }),
);

export function AddUser() {
  const history = useHistory();
  const userProfile = useContext(UserProfileContext)!;
  const [createInvitation, { loading, data, error }] = useCreateInvitation();
  const [roleId, setRoleId] = useState('');
  const [email, setEmail] = useState<string>('');
  const [siteIds, setSiteIds] = useState<number[]>([]);
  const classes = useStyles();

  if (data?.createInvitation) {
    return <Redirect to="/admin/users" />;
  }

  const handleSubmit = () => {
    const invitation: CreateInvitationInputType = {
      email,
      roleId,
      // For now, we assume new user has same modules as current user
      modules: userProfile.modules,
      siteIds,
    };

    const variables: CreateInvitationMutationVariables = { invitation };
    createInvitation({ variables });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value.trim());
  };

  const onCancel = () => {
    const route = '/admin/users';
    history.push(route);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              Invite user
            </>
          </Typography>
          <AdminBreadcrumbs subtitle="Users" sublink="users" title="Invite user" />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />

      <AdminNavigation path="/admin/users" />

      <div className={classes.navMargin}>
        <ActionButtons>
          <LinkButton
            to="/admin/users"
            startIcon={<ArrowBackIcon />}
            aria-label="back"
            tooltip="Return to users list"
          >
            Back
          </LinkButton>
        </ActionButtons>
      </div>

      {error && <ErrorDisplay error={error} />}

      <Card>
        <CardContent>
          <form>
            <fieldset>
              <MandatoryFieldMessage />
              <Grid container spacing={3}>
                <Grid item xs={12} className="tillr-form-grid-element">
                  <TextField
                    id="email"
                    label="Email address"
                    value={email}
                    variant="outlined"
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: !!(email as string)?.length,
                    }}
                    fullWidth
                  />
                </Grid>
                <RolePickList roleId={roleId} onChange={setRoleId} required />
              </Grid>
              <Grid item xs={12}>
                <HorizontalLine margin={[0, 0, 1, 0]} />
                <Typography variant="h6">
                  Invited user will have access to the following sites:
                </Typography>
              </Grid>
              <SiteMultiCheckboxes readonly={false} siteIds={siteIds} onChange={setSiteIds} />
              <FormsButtons>
                <CancelButton label="Cancel" loading={loading} onClick={onCancel} />
                <ProgressButton label="Send invite" loading={loading} onClick={handleSubmit} />
              </FormsButtons>
            </fieldset>
          </form>
        </CardContent>
      </Card>
    </>
  );
}
