import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useGetMyTasks } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { FilterControl, FilterType, IFilter } from 'components/Filtering/FilterControl';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleLinkButton } from 'components/Modules/ModuleLinkButton';
import { PaginationControl } from 'components/Pagination/PaginationControl';
import { PreviousNextControl } from 'components/Pagination/PreviousNextControl';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { ListControls } from 'components/Shared/ListControls';
import { ProgressBar } from 'components/Shared/ProgressBar';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { PaginationOrder, TaskFilterBy, TaskOrderBy, TaskPaginationPropsType } from 'tillr-graphql';
import { SwitchViewControl } from '../SwitchViewControl';
import { QuickTask } from './QuickTask';
import { ResultsTable } from './ResultsTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    actions: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

const filterTypeMappings = new Map([
  [TaskFilterBy.Assignee, FilterType.Assignee],
  [TaskFilterBy.Closed, FilterType.Boolean],
  [TaskFilterBy.DueDate, FilterType.Date],
  [TaskFilterBy.Name, FilterType.String],
]);

export function TasksList() {
  const classes = useStyles();
  const paginationOptions = {
    pageSizes: [5, 10, 100],
    orderBys: Object.values(TaskOrderBy),
    orders: Object.values(PaginationOrder),
  };
  const paginationProps = {
    pageSize: paginationOptions.pageSizes[2],
    orderBy: paginationOptions.orderBys[0],
    order: paginationOptions.orders[0],
  };

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data, refetch, fetchMore } = useGetMyTasks({
    siteId,
    module,
    paginationProps,
  });

  const handleFetchPrevious = () => {
    fetchMore({ variables: { before: data?.myTasks?.startCursor } });
  };

  const handleFetchNext = () => {
    fetchMore({ variables: { after: data?.myTasks?.endCursor } });
  };

  const handleChangeFilter = (nextFilter: IFilter<TaskFilterBy>) => {
    refetch({ filterProps: { filters: [nextFilter] } });
  };

  const handleChangePaginationProps = (nextPaginationProps: TaskPaginationPropsType) => {
    refetch({ paginationProps: nextPaginationProps });
  };

  const handleQuickTaskCreated = () => {
    refetch();
  };

  return (
    <div>
      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {data?.myTasks?.items && (
        <>
          <Grid container alignItems="center" className={classes.root}>
            <Grid md={6} container item>
              <SwitchViewControl currentView="list" />
            </Grid>
            <Grid
              md={6}
              container
              item
              // justify="flex-end"
              alignItems="center"
              className={classes.actions}
            >
              <ActionButtons margin="no-inner-margin" permission={['Tasks.Edit']}>
                <QuickTask onCreated={handleQuickTaskCreated} />
                <ModuleLinkButton
                  to="/tasks/add"
                  aria-label="add"
                  startIcon={<AddIcon />}
                  tooltip="Create new task"
                >
                  Create new
                </ModuleLinkButton>
              </ActionButtons>
            </Grid>
          </Grid>
          <Paper>
            <ListControls>
              <PaginationControl<TaskOrderBy>
                initialProps={paginationProps}
                options={paginationOptions}
                onRefetch={handleChangePaginationProps}
              />
              <FilterControl<TaskFilterBy>
                filterByValues={Object.values(TaskFilterBy)}
                filterTypeMappings={filterTypeMappings}
                onChange={handleChangeFilter}
              />
              <PreviousNextControl
                hasPreviousPage={data.myTasks.hasPreviousPage}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={data.myTasks.hasNextPage}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
            <HorizontalLine margin={[1, 0, 1, 0]} />
            <ResultsTable tasks={data.myTasks.items} />
            <ListControls>
              <PreviousNextControl
                hasPreviousPage={data.myTasks.hasPreviousPage}
                onFetchPrevious={handleFetchPrevious}
                hasNextPage={data.myTasks.hasNextPage}
                onFetchNext={handleFetchNext}
              />
            </ListControls>
          </Paper>
        </>
      )}
    </div>
  );
}
