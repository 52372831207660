import { IComponentProps } from 'formly/IComponentProps';
import { withComponentPropsMemo } from 'formly/withComponentPropsMemo';
import React from 'react';

const TodoField = withComponentPropsMemo(function TodoField(props: IComponentProps) {
  const { field, modelValue, onValueUpdate } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (field.key) {
      onValueUpdate(field.key, event.currentTarget.value);
    }
  };

  return (
    <div>
      <p>
        <strong>{`TODO: ${field.type}`}</strong>
      </p>
      <p>
        <label htmlFor={field.key}>{field.templateOptions?.label}</label>
      </p>
      <input id={field.key} name={field.key} value={modelValue} onChange={handleChange} />
    </div>
  );
});

export { TodoField };
