import AddIcon from '@material-ui/icons/Add';
import { ModuleLinkButton } from 'components/Modules/ModuleLinkButton';
import React from 'react';

interface IProps {
  templateId: string;
  templateName: string;
}

export function AddFormButton(props: IProps) {
  const { templateId, templateName } = props;

  return (
    <ModuleLinkButton
      to={`/form-templates/${templateId}/forms/add`}
      aria-label="add"
      startIcon={<AddIcon />}
      tooltip={`Create new ${templateName}`}
    >
      Create new
    </ModuleLinkButton>
  );
}

