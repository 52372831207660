import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDeleteLibraryFile } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ProgressBar } from 'components/Shared/ProgressBar';
import React, { useState } from 'react';
import { DeleteLibraryFileMutationVariables } from 'tillr-graphql';

interface IProps {
  file: { id: string; name: string };
  folderId: string;
  onDeleted?: (folderId: string, fileId: string) => void;
}

export function DeleteFileControl(props: IProps) {
  const { file, folderId, onDeleted } = props;
  const [open, setOpen] = useState(false);

  const [deleteLibraryFile, { loading, error, data, reset }] = useDeleteLibraryFile({ folderId });

  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const variables: DeleteLibraryFileMutationVariables = { folderId, fileId: file.id };
    deleteLibraryFile({ variables });
  };

  const handleClickOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  };

  if (data?.deleteLibraryFile) {
    if (onDeleted) {
      onDeleted(data.deleteLibraryFile.folderId, data.deleteLibraryFile.id);
    }
    setOpen(false);
    reset();
  }

  return (
    <>
      <Tooltip title={`Delete '${file.name}`}>
        <IconButton className="action-button" onClick={handleClickOpen}>
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">Remove file</DialogTitle>
        <DialogContent>
          {loading && <ProgressBar />}
          {error && <ErrorDisplay error={error} />}
          {!loading && !error && (
            <Typography variant="body1" display="block">
              {`Are you certain you want to remove the file '${file.name}' from this folder?`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} disabled={loading} color="primary">
            {`Yes, remove '${file.name}'`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
