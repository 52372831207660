import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Authenticate } from './auth/Authenticate';
import { Authenticated } from './auth/Authenticated';
import { AuthenticatedRoute } from './auth/AuthenticatedRoute';
import { AuthenticatedSilentRenew } from './auth/AuthenticatedSilentRenew';
import { Logout } from './auth/Logout';
import { Unauthorised } from './auth/Unauthorised';
import { Welcome } from './auth/Welcome';
import { AdminHome } from './components/Admin/Home';
import { BuildNumbersDisplay } from './components/BuildNumbers/BuildNumbersDisplay';
import { Dashboard } from './components/Dashboard/Dashboard';
import { AddFile } from './components/Files/AddFile';
import { AddFileNote } from './components/Files/AddFileNote';
import { EditFile } from './components/Files/EditFile';
import { File } from './components/Files/File';
import { FileAttachment } from './components/Files/FileAttachment';
import { Files } from './components/Files/Files';
import { AddForm } from './components/Forms/AddForm';
import { EditForm } from './components/Forms/EditForm';
import { Form } from './components/Forms/Form';
import { Forms } from './components/Forms/Forms';
import { FormTemplates } from './components/Forms/FormTemplates';
import { OfflineForm } from './components/Forms/OfflineForm';
import { OfflineForms } from './components/Forms/OfflineForms';
import { WebpackChunkWrapper as HQ } from './components/HQ/WebpackChunkWrapper';
import { Library } from './components/Library/Library';
import { ModuleContext } from './components/Modules/ModuleContext';
import { EditNotificationPreferences } from './components/NotificationPreferences/EditNotificationPreferences';
import { NotificationPreferences } from './components/NotificationPreferences/NotificationPreferences';
import { Colors } from './components/Reports/Colors';
import { WebpackChunkWrapper as Reports } from './components/Reports/WebpackChunkWrapper';
import { getSiteModuleRoute } from './components/route-utils';
import { SiteContext } from './components/Sites/SiteContext';
import { AddTask } from './components/Tasks/AddTask';
import { AddTaskNote } from './components/Tasks/AddTaskNote';
import { EditTask } from './components/Tasks/EditTask';
import { Task } from './components/Tasks/Task';
import { Tasks } from './components/Tasks/Tasks';
import { Team } from './components/Teams/Team';
import { Teams } from './components/Teams/Teams';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      overflow: 'hidden',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#dedede',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2147483647,
    },
  }),
);

export const Routes = () => {
  const loadingTimer: number = 0;
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (timer) {
      clearTimeout(timer);
    }
    if (!loaded) {
      timer = setTimeout(() => {
        setLoaded(true);
      }, loadingTimer);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [location, loaded]);

  function LoadingCloak() {
    return (
      <div className={classes.root}>
        <div className="tillr-loading">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <>
      {!loaded && <LoadingCloak />}
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={getSiteModuleRoute(siteId, module, 'dashboard')} />}
        />
        <Route exact path="/status" component={BuildNumbersDisplay} />
        <Route exact path="/authenticate" component={Authenticate} />
        <Route exact path="/authenticated" component={Authenticated} />
        <Route exact path="/authenticated/silent-renew" component={AuthenticatedSilentRenew} />
        <Route path="/welcome" component={Welcome} />
        <Route path="/unauthorised" component={Unauthorised} />
        <Route path="/logout" component={Logout} />
        <AuthenticatedRoute
          permissions={['Sites.View', 'Users.View']}
          path="/admin"
          component={AdminHome}
        />
        <AuthenticatedRoute
          permissions={['Library.Download']}
          exact
          path="/library/:folderId?"
          component={Library}
        />
        <AuthenticatedRoute
          permissions={['HQ.Admin']}
          exact
          path="/notification-preferences"
          component={NotificationPreferences}
        />
        <AuthenticatedRoute
          permissions={['HQ.Admin']}
          exact
          path="/notification-preferences/edit"
          component={EditNotificationPreferences}
        />
        <AuthenticatedRoute permissions={['HQ.Admin']} path="/hq" component={HQ} />

        <AuthenticatedRoute
          permissions={['Forms.View']}
          exact
          path="/offline-forms/:formId"
          component={OfflineForm}
        />
        <AuthenticatedRoute
          permissions={['Forms.View']}
          exact
          path="/offline-forms"
          component={OfflineForms}
        />
        <Route path="/site-:siteId/:module">
          <Switch>
            <AuthenticatedRoute
              exact
              path="/site-:siteId/:module/dashboard"
              component={Dashboard}
            />
            <AuthenticatedRoute
              permissions={['Files.View']}
              exact
              path="/site-:siteId/:module/files/:fileId"
              component={File}
            />
            <AuthenticatedRoute
              permissions={['Files.View']}
              exact
              path="/site-:siteId/:module/files/:fileId/attachments/:attachmentId"
              component={FileAttachment}
            />
            <AuthenticatedRoute
              permissions={['Files.Edit']}
              exact
              path="/site-:siteId/:module/files/:fileId/edit"
              component={EditFile}
            />
            <AuthenticatedRoute
              permissions={['Files.Edit']}
              exact
              path="/site-:siteId/:module/files/:fileId/notes/add"
              component={AddFileNote}
            />
            <AuthenticatedRoute
              permissions={['Files.View']}
              exact
              path="/site-:siteId/:module/file-types/:fileTypeId"
              component={Files}
            />
            <AuthenticatedRoute
              permissions={['Files.Edit']}
              exact
              path="/site-:siteId/:module/file-types/:fileTypeId/add"
              component={AddFile}
            />
            <AuthenticatedRoute
              permissions={['Forms.View']}
              exact
              path="/site-:siteId/:module/form-templates"
              component={FormTemplates}
            />
            <AuthenticatedRoute
              permissions={['Forms.View']}
              exact
              path="/site-:siteId/:module/form-templates/:formTemplateId/forms"
              component={Forms}
            />
            <AuthenticatedRoute
              permissions={['Forms.Edit']}
              exact
              path="/site-:siteId/:module/form-templates/:formTemplateId/forms/add"
              component={AddForm}
            />
            <AuthenticatedRoute
              permissions={['Forms.View']}
              exact
              path="/site-:siteId/:module/forms/:formId"
              component={Form}
            />
            <AuthenticatedRoute
              permissions={['Forms.Edit']}
              exact
              path="/site-:siteId/:module/forms/:formId/edit"
              component={EditForm}
            />
            <AuthenticatedRoute
              require-all
              permissions={['Reports.View', 'Reports.PageView']}
              path="/site-:siteId/:module/reports"
              component={Reports}
            />
            <AuthenticatedRoute
              permissions={['Tasks.View']}
              exact
              path="/site-:siteId/:module/tasks"
              component={Tasks}
            />
            <AuthenticatedRoute
              permissions={['Tasks.Edit']}
              exact
              path="/site-:siteId/:module/tasks/add"
              component={AddTask}
            />
            <AuthenticatedRoute
              permissions={['Tasks.View']}
              exact
              path="/site-:siteId/:module/tasks/:taskId"
              component={Task}
            />
            <AuthenticatedRoute
              permissions={['Tasks.Edit']}
              exact
              path="/site-:siteId/:module/tasks/:taskId/edit"
              component={EditTask}
            />
            <AuthenticatedRoute
              permissions={['Tasks.Edit']}
              exact
              path="/site-:siteId/:module/tasks/:taskId/notes/add"
              component={AddTaskNote}
            />
            <AuthenticatedRoute
              permissions={['HQ.Admin']}
              exact
              path="/site-:siteId/:module/teams"
              component={Teams}
            />
            <AuthenticatedRoute
              permissions={['HQ.Admin']}
              exact
              path="/site-:siteId/:module/teams/:teamId"
              component={Team}
            />
          </Switch>
        </Route>

        <AuthenticatedRoute
          permissions={['Reports.View']}
          path="/reports/colors"
          component={Colors}
        />
      </Switch>
    </>
  );
};
