import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { UserAvatar } from 'components/Shared/UserAvatar';
import React from 'react';

interface IAssignee {
  id: string;
  name: string;
}

interface IAssignees {
  users: IAssignee[];
  teams: IAssignee[];
}

export function AssigneesSummary(props: IAssignees) {
  const { users, teams } = props;
  const assignees = users.concat(teams);
  return (
    <>
      <AvatarGroup max={2}>
        {assignees.map((assignee) => (
          <UserAvatar key={assignee.id} name={assignee.name} id={assignee.id} />
        ))}
      </AvatarGroup>
    </>
  );
}
