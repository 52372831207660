import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useContext, useState } from 'react';
import { Module } from 'tillr-graphql';
import { UserProfileContext } from 'UserProfileContext';
import { ModuleContext } from './ModuleContext';

function getModuleDetails(module: Module) {
  switch (module) {
    case Module.TillrPlatform:
      return {
        title: 'Tillr',
        avatar: 'TM',
      };
    case Module.FacilitiesMonitoring:
      return {
        title: 'Facilities Monitoring',
        avatar: 'FM',
      };
    case Module.HealthAndSafety:
      return {
        title: 'Health & Safety',
        avatar: 'HS',
      };
    case Module.Gdpr:
      return {
        title: 'GDPR',
        avatar: 'G',
      };
    case Module.RiskManagement:
      return {
        title: 'Risk Management',
        avatar: 'RM',
      };
    case Module.PerformanceCoaching:
      return {
        title: 'Performance Coaching',
        avatar: 'PC',
      };
    case Module.QualityAssurance:
      return {
        title: 'Quality Assurance',
        avatar: 'QA',
      };
    case Module.Lite:
      return {
        title: 'Lite',
        avatatr: 'L',
      };
    default:
      return {
        title: '',
        avatar: '',
      };
  }
}

export function ModuleControl() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userProfile = useContext(UserProfileContext);
  const moduleContext = useContext(ModuleContext);

  if (!userProfile || userProfile.modules.length <= 1 || !moduleContext) {
    return null;
  }

  const { module, setModule } = moduleContext;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    if (event.currentTarget?.dataset?.module) {
      setModule(event.currentTarget.dataset.module as Module);
    }
  };

  return (
    <>
      {userProfile.modules.length && (
        <Tooltip title="Switch module">
          <ListItem button>
            <ListItemIcon
              className="nav-icon"
              aria-controls="module-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Avatar>{getModuleDetails(module || Module.TillrPlatform)?.avatar}</Avatar>
            </ListItemIcon>
            <ListItemText
              className="nav-link"
              aria-controls="module-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {getModuleDetails(module || Module.TillrPlatform)?.title}
            </ListItemText>
            <Menu
              id="module-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {userProfile.modules.map((x) => (
                <MenuItem
                  key={x}
                  data-module-label={getModuleDetails(x)?.title}
                  data-module={x}
                  onClick={handleClose}
                  selected={x === module}
                >
                  {getModuleDetails(x)?.title}
                </MenuItem>
              ))}
            </Menu>
          </ListItem>
        </Tooltip>
      )}
    </>
  );
}
