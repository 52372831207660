import { Button, Grid } from '@material-ui/core';
import { useDetectTenantType } from 'components/Custom/useDetectTenantType';
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router';
import { AuthControls } from './AuthControls';

type IState = 'welcome' | 'redirecting' | 'validating' | 'error';

interface IProps {
  message?: string;
  state?: IState;
}

export function AuthScreen(props: IProps) {
  const { message = '', state = 'welcome' } = props;
  const year = format(new Date(), 'yyyy');
  const [tenantType] = useDetectTenantType();
  const history = useHistory();

  let altText = 'Tillr';
  if (tenantType === 'squirrel') {
    altText = 'Squirrel';
  }
  if (tenantType === 'systemconcepts') {
    altText = 'System Concepts';
  }

  const handleTryAgain = () => {
    history.push('/');
  };

  return (
    <>
      <div className={`welcome-screen welcome-screen--${tenantType}`}>
        <div className="welcome-screen__actions">
          <section>
            <div>
              <h1>
                <img src={`/images/${tenantType}.svg`} alt={altText} />
              </h1>
              {state === 'welcome' && (
                <>
                  <h2>Welcome</h2>
                  <p>To continue, please log in to your account by clicking the button below.</p>
                  <AuthControls />
                </>
              )}
              {state === 'redirecting' && (
                <>
                  <h2>One moment please</h2>
                  <p>Redirecting you to authentication.</p>
                </>
              )}
              {state === 'validating' && (
                <>
                  <h2>One moment please</h2>
                  <p>Validating your account credentials...</p>
                </>
              )}
              {state === 'error' && (
                <>
                  <h2>Unable to log in</h2>
                  <p>{message}</p>
                  <Button variant="contained" color="primary" onClick={handleTryAgain}>
                    Try again
                  </Button>
                </>
              )}
            </div>
            <p>
              Need some help?{' '}
              {tenantType === 'squirrel' ? (
                <a
                  href="mailto:squirrel@vetpartners.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Email squirrel@vetpartners.co.uk (opens in a new tab/window)"
                >
                  Contact support
                </a>
              ) : (
                <a
                  href="mailto:support@tillr.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Email support@tillr.io (opens in a new tab/window)"
                >
                  Contact support
                </a>
              )}
              .
            </p>
          </section>
        </div>
        <div className="welcome-screen__abstract">
          <div className="welcome-screen__content" />
          <div className="welcome-screen__footer">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <p>Powered by Tillr</p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <p className="welcome-screen__copy">&copy; Telamon Software Ltd {year}</p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
