import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { getApiBaseUrl } from './environment-utils';
import { UserProfile } from './UserProfile';

export function createApolloClient(userProfile: UserProfile | null): ApolloClient<unknown> {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          forms: relayStylePagination([
            'siteId',
            'module',
            'formTemplateId',
            'filterProps',
            'paginationProps',
          ]),
          formTemplates: relayStylePagination([
            'siteId',
            'module',
            'filterProps',
            'paginationProps',
          ]),
          myFiles: relayStylePagination([
            'siteId',
            'module',
            'fileTypeId',
            'filterProps',
            'paginationProps',
          ]),
          myTasks: relayStylePagination([
            'siteId',
            'module',
            'filterProps',
            'filterProps',
            'paginationProps',
          ]),
          systemFormTemplates: relayStylePagination(),
        },
      },
    },
  });

  // `readQuery` throws an error if the query is not yet in the cache.
  // Let's patch the method to catch this error and return null instead.
  // (See https://github.com/apollographql/apollo-feature-requests/issues/1)
  const monkeyPatchingCache = cache as any;
  monkeyPatchingCache.originalReadQuery = cache.readQuery;
  cache.readQuery = (...args) => {
    try {
      return monkeyPatchingCache.originalReadQuery(...args);
    } catch (err) {
      return null;
    }
  };

  return new ApolloClient({
    cache,
    headers: {
      Authorization: userProfile?.authHeaderValue || '',
    },
    uri: `${getApiBaseUrl()}/graphql`,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'ignore',
      },
    },
  });
}
