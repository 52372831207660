import { ButtonProps } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';

export function ModuleListItem(props: ButtonProps & LinkProps) {
  const { to, ...rest } = props;
  const {
    params: { module },
  } = useRouteMatch<{ module: string }>();
  function ModuleListItemText() {
    return <ListItemText primary="Add new note" />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <ListItem button component={Link} to={`/${module}${to}`} {...rest}>
      <ModuleListItemText />
    </ListItem>
  );
}
