import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { IComponentProps } from 'formly/IComponentProps';
import { withComponentPropsMemo } from 'formly/withComponentPropsMemo';
import React, { useEffect } from 'react';
import { ElementInfo } from './shared/ElementInfo';
import { HelpText } from './shared/HelpText';
import { FieldValidationMessage, validationStyles } from './shared/Validation';

const MultiCheckboxes = withComponentPropsMemo(function MultiCheckboxes(props: IComponentProps) {
  const classes = validationStyles();
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    getValues,
    clearErrors,
    setError,
  } = props;
  let valueArray = (modelValue as Array<string>) || [];

  const customTrigger = () => {
    const values = getValues();
    if (field.templateOptions?.required && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: 'You must select at least one option.',
      });
    } else {
      clearErrors(field.key);
    }
  };

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, valueArray);
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  if (!field.templateOptions?.options?.length) {
    // eslint-disable-next-line no-console
    console.warn(`FORMLY: MultiCheckboxes without options: ${field.key}`);
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!field.key) return;
    const { checked, value } = event.currentTarget;
    if (checked) {
      valueArray = valueArray.concat([value]);
    } else {
      valueArray = valueArray.filter((v: string) => v !== value);
    }
    onValueUpdate(field.key, valueArray);
    setValue(field.key, valueArray);
    customTrigger();
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <FormControl component="fieldset" disabled={readonly} error={!!errors[field.key!]}>
          <FormLabel component="legend">
            {field.templateOptions?.label}
            {!readonly && field.templateOptions?.required && (
              <span className={classes.asterisk}>*</span>
            )}
            <ElementInfo inline data={field.data} />
          </FormLabel>
          <FormGroup>
            {field.templateOptions.options.map((option, i) => {
              const htmlId = `${field.key}_${i}`;
              const optionValue = option.value || option.name;
              return (
                <FormControlLabel
                  key={htmlId}
                  checked={valueArray.indexOf(optionValue) >= 0}
                  control={<Checkbox onChange={handleChange} id={htmlId} value={optionValue} />}
                  label={optionValue}
                />
              );
            })}
          </FormGroup>
          {errors[field.key!] && (
            <FieldValidationMessage className="non-input" message={errors[field.key!].message} />
          )}
          {field.data?.help && <HelpText value={field.data?.help} />}
        </FormControl>
      </Grid>
    </>
  );
});

export { MultiCheckboxes };
