import { Typography } from '@material-ui/core';
import { useGetMyReport } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { getReportConfig } from 'components/Reports/IReportConfig';
import { ReportDataView } from 'components/Reports/ReportDataView';
import { ReportIdType, useRunReport } from 'components/Reports/useRunReport';
import { IModel } from 'formly/IModel';
import React, { useContext, useEffect } from 'react';

interface IProps {
  reportId: string;
  viewIndex: number | undefined;
  inputParameters: IModel | undefined;
}

export function ReportWidget(props: IProps) {
  const { reportId, viewIndex = 0, inputParameters = {} } = props;

  const { module } = useContext(ModuleContext)!;
  const getReportState = useGetMyReport({ module, id: reportId });
  const [runReport, runReportState] = useRunReport();

  useEffect(() => {
    runReport(reportId, ReportIdType.Report, inputParameters);
    // eslint-disable-next-line
  }, [reportId]);

  if (getReportState.error) {
    return <ErrorDisplay error={getReportState.error} />;
  }
  if (runReportState.error) {
    return <ErrorDisplay error={runReportState.error} />;
  }

  if (getReportState.loading || runReportState.loading) {
    return <ActivityIndicator />;
  }

  return (
    <div>
      {getReportState.data?.myReport?.queryDefinition && runReportState.data ? (
        <>
          <Typography variant="h2" noWrap>
            {getReportState.data.myReport.name}
          </Typography>
          <ErrorBoundary>
            <ReportDataView
              inputParametersDefinition={[]}
              inputParameters={inputParameters}
              reportConfig={getReportConfig(getReportState.data.myReport.uiData)}
              viewIndex={viewIndex}
              data={runReportState.data}
              resultColumns={getReportState.data.myReport.queryDefinition.resultColumns}
            />
          </ErrorBoundary>
        </>
      ) : (
        <>
          <p>Not found.</p>
        </>
      )}
    </div>
  );
}
