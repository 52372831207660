import { useGetTeamsAndUsers } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { ChangeEvent, useContext } from 'react';

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

export function AssigneePickList(props: IProps) {
  const { value, onChange } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetTeamsAndUsers({ siteId, module });

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(event.currentTarget.value as string);
  };

  const sortByName = (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name);

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data && (
        <select defaultValue={value} onChange={handleChange}>
          <option disabled value="">
            -- Select Assignee --
          </option>
          {data.users?.length && [
            <option key="Users" disabled>
              Users
            </option>,
            data.users
              .slice()
              .sort(sortByName)
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              )),
          ]}
          {data.teams?.length && [
            <option key="Teams" disabled>
              Teams
            </option>,
            data.teams
              .slice()
              .sort(sortByName)
              .map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              )),
          ]}
        </select>
      )}
    </>
  );
}
