import { Paper } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { ProgressSkeleton } from 'components/Shared/ProgressSkeleton';
import React from 'react';
import { siteStyles } from './siteStyles';

export function LoadingSites() {
  const classes = siteStyles();
  return (
    <>
      <Paper
        classes={{
          root: classes.paperRoot,
        }}
      >
        <TreeView
          className={classes.root}
          expanded={['1', '2', '3', '4', '5', '6']}
          defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
          defaultExpandIcon={<ChevronRightIcon color="primary" />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          <TreeItem
            nodeId="1"
            label="Admin site"
            classes={{
              content: classes.content,
              group: classes.group,
              label: classes.label,
            }}
          >
            <TreeItem
              nodeId="2"
              label={<ProgressSkeleton variant="rect" height={33} />}
              classes={{
                content: classes.content,
                group: classes.group,
                label: classes.label,
              }}
            >
              <TreeItem
                nodeId="3"
                label={<ProgressSkeleton variant="rect" height={33} />}
                classes={{
                  content: classes.content,
                  group: classes.group,
                  label: classes.label,
                }}
              />
              <TreeItem
                nodeId="4"
                label={<ProgressSkeleton variant="rect" height={33} />}
                classes={{
                  content: classes.content,
                  group: classes.group,
                  label: classes.label,
                }}
              />
            </TreeItem>
            <TreeItem
              nodeId="5"
              label={<ProgressSkeleton variant="rect" height={33} />}
              classes={{
                content: classes.content,
                group: classes.group,
                label: classes.label,
              }}
            />
            <TreeItem
              nodeId="6"
              label={<ProgressSkeleton variant="rect" height={33} />}
              classes={{
                content: classes.content,
                group: classes.group,
                label: classes.label,
              }}
            />
          </TreeItem>
        </TreeView>
      </Paper>
    </>
  );
}
