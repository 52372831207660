import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import StopIcon from '@material-ui/icons/Stop';
import { useDetectTenantType } from 'components/Custom/useDetectTenantType';
import { SiteControl2 } from 'components/Sites/SiteControl2';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useUiDataProvider } from 'UiDataProvider';
import { UserManagerContext } from 'UserManagerContext';
import { UserProfileContext } from 'UserProfileContext';
import { formatAsFriendlyDate, getStartUpRoute } from 'utils';
import { AccidentReport } from './AccidentReport';
import { Documents } from './Documents';
import { QuickLinks } from './QuickLinks';
import { TasksStatus } from './TasksStatus';
import { Widgets } from './Widgets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      height: '100%',
    },
    image: {
      maxWidth: '480px',
      display: 'block',
      margin: '104px auto 0',
      [theme.breakpoints.down('xs')]: {
        margin: '16px auto 0',
      },
    },
    welcomeHeader: {
      fontSize: '2.5rem',
      marginBottom: theme.spacing(2),
    },
    welcomeText: {
      fontSize: '1.2rem',
      marginBottom: theme.spacing(2),
    },
    grid: {
      alignItems: 'stretch',
    },
    list: {
      marginBottom: theme.spacing(2),
      '& .MuiListItemText-primary': {
        fontSize: 16.5,
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    highlight: {
      fontWeight: 'normal',
      color: theme.palette.primary.main,
    },
  }),
);

export function Dashboard() {
  const classes = useStyles();
  const today = formatAsFriendlyDate(new Date());
  const userProfile = useContext(UserProfileContext);

  const [redirectPath, setRedirectPath] = useState<null | string>(null);
  // TODO: move this code into uiData
  const [tenantType, isDashboardTenant] = useDetectTenantType();
  const { uiData } = useUiDataProvider();

  // This is temporary while rolling out Permissions
  const userManager = useContext(UserManagerContext)!;
  if (userProfile && !userProfile?.permissions?.length) {
    userManager.signoutRedirect();
  }

  const userName = userProfile?.name;

  // Calculate how many Dashboard elements we can render
  let hasPermissions: string[] = [];
  const permissionsRequired = [
    'Dashboard.ViewTaskStatus',
    'Dashboard.ViewAccidentReport',
    'Dashboard.ViewPropertyCompliance',
  ];
  if (userProfile) {
    hasPermissions = permissionsRequired.filter((perm) => userProfile?.hasAnyPermission([perm]));
  }

  useEffect(() => {
    if (userProfile) {
      const route = getStartUpRoute(true);
      if (route && route !== window.location.pathname) {
        setRedirectPath(route);
      }
    }
  }, [userProfile, setRedirectPath]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            Welcome back
            {userName && userName.length > 0 && <>{`, ${userName}`}</>}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className="bottom-align">
          <Typography variant="body2" align="right">
            {today}
          </Typography>
        </Grid>
      </Grid>

      <Divider className="divider" />

      {uiData && (uiData.showSiteControl || uiData.quickLinks) && (
        <Box mb={3}>
          <Paper className={classes.paper}>
            {uiData.showSiteControl && (
              <Box>
                <SiteControl2 />
              </Box>
            )}
            {uiData.quickLinks && <QuickLinks links={uiData.quickLinks} />}
          </Paper>
        </Box>
      )}

      {uiData?.dashboards && (
        <div className={classes.root}>
          {uiData.dashboards.map((dashboard, dashboardIndex) => {
            if (!userProfile?.hasAnyPermission(dashboard.permissions)) {
              return null;
            }
            return <Widgets key={dashboardIndex} widgets={dashboard.widgets} />;
          })}
        </div>
      )}

      {tenantType === 'systemconcepts' && (
        <>
          <Typography variant="h2" display="block" className={classes.welcomeHeader}>
            OBSL&apos;s
            <strong className={classes.highlight}> H&S risk assessment </strong>
            toolkit
          </Typography>
          <Typography variant="body1" display="block" className={classes.welcomeText}>
            Select &apos;Forms&apos; to access a suite of risk assessments covering all aspects of{' '}
            occupational health and safety;
          </Typography>
          <List dense className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="Accident, Near Miss and Ill Health Report" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="Agile Working Assessment" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="Event Information and Assessment" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="Homeworker Assessment (contractual)" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="New &amp; Expectant Mothers Assessment" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="New starter H&amp;S Checklist" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <StopIcon />
              </ListItemIcon>
              <ListItemText primary="Personal Emergency Evacuation Plan (PEEP) Assessment" />
            </ListItem>
          </List>
          <Typography variant="body1" display="block" className={classes.welcomeText}>
            For more information, please visit our
            <strong className={classes.highlight}> Health &amp; safety </strong>
            at work intranet pages or email{' '}
            <a href="mailto:ukgroup.healthandsafety@orange.com" className={classes.highlight}>
              ukgroup.healthandsafety@orange.com
            </a>
            .
          </Typography>
        </>
      )}

      {isDashboardTenant && (
        <>
          {userProfile && userProfile?.hasAnyPermission(['Dashboard.View']) ? (
            <div className={classes.root}>
              <Grid container spacing={3} className={classes.grid}>
                {userProfile?.hasAnyPermission(['Dashboard.ViewTaskStatus']) && (
                  <Grid item sm={12} md={4}>
                    <Paper className={classes.paper}>
                      <TasksStatus />
                    </Paper>
                  </Grid>
                )}
                {userProfile?.hasAnyPermission(['Dashboard.ViewAccidentReport']) && (
                  <Grid item sm={12} md={4}>
                    <Paper className={classes.paper}>
                      <AccidentReport />
                    </Paper>
                  </Grid>
                )}
                {userProfile?.hasAnyPermission(['Dashboard.ViewPropertyCompliance']) && (
                  <Documents hasPermissions={hasPermissions.length} />
                )}
              </Grid>
            </div>
          ) : (
            <>
              {tenantType === 'squirrel' && (
                <img
                  src="/images/large-squirrel-logo.svg"
                  className={classes.image}
                  alt="Squirrel"
                />
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
}
