export const LIBRARY_HOME_FOLDER_ID = '00000000-0000-0000-0000-000000000000';

export function isHomeFolder(folderId?: string): boolean {
  return !folderId || folderId === LIBRARY_HOME_FOLDER_ID;
}

export function getLibraryFolderPath(folderId?: string): string {
  return `/library${isHomeFolder(folderId) ? '' : `/${folderId}`}`;
}

