import Button from '@material-ui/core/Button';
import React, { useContext, useState } from 'react';
import { UserManagerContext } from 'UserManagerContext';
import { UserProfileContext } from 'UserProfileContext';

export function AuthControls() {
  const userManager = useContext(UserManagerContext)!;
  const userProfile = useContext(UserProfileContext)!;

  const [redirecting, setRedirecting] = useState(false);

  const handleClick = () => {
    setRedirecting(true);
    userManager.signinRedirect();
  };

  if (!userProfile) {
    return (
      <Button variant="contained" color="primary" onClick={handleClick} disabled={redirecting}>
        {redirecting ? 'Redirecting...' : 'Continue'}
      </Button>
    );
  }

  return <span />;
}
