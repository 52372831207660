import { IComponentProps } from 'formly/IComponentProps';
import { withComponentPropsMemo } from 'formly/withComponentPropsMemo';
import React from 'react';

const Hidden = withComponentPropsMemo(function Hidden(props: IComponentProps) {
  const { field, modelValue } = props;

  return (
    <div>
      <input type="hidden" name={field.key} value={modelValue || ''} />
    </div>
  );
});

export { Hidden };
