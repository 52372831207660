import { Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useGetMyFile, useUpdateFile } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleRedirect } from 'components/Modules/ModuleRedirect';
import { SiteContext } from 'components/Sites/SiteContext';
import { FormlyForm } from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import React, { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { UpdateFileMutationVariables, UpdateFormInputType } from 'tillr-graphql';
import { FileBreadcrumbs } from './FileBreadcrumbs';
import { buildFormlyConfig, buildModel, splitModel } from './utils';

export function EditFile(props: RouteComponentProps<{ fileId: string }>) {
  const {
    match: {
      params: { fileId },
    },
  } = props;

  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const queryState = useGetMyFile({ siteId, module, id: fileId });
  const [updateFile, mutationState] = useUpdateFile({ siteId, module, id: fileId });
  const [cancelled, setCancelled] = useState(false);

  if (cancelled || mutationState.data?.updateFile) {
    return <ModuleRedirect to={`/files/${fileId}`} />;
  }

  const handleSubmit = (model: IModel) => {
    const [name, formDataJson] = splitModel(model);
    const form: UpdateFormInputType = {
      id: queryState.data!.myFile!.formId,
      name,
      formDataJson,
      isSubmitted: false,
    };

    const variables: UpdateFileMutationVariables = {
      siteId,
      module,
      fileId,
      form,
    };
    updateFile({ variables });
  };

  const handleCancel = () => {
    setCancelled(true);
  };

  return (
    <div>
      {queryState.loading && <ActivityIndicator />}
      {queryState.error && <ErrorDisplay error={queryState.error} />}
      {mutationState.error && <ErrorDisplay error={mutationState.error} />}
      {queryState.data?.myFile?.form && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">
                {queryState.data.myFile.name}
              </Typography>
              <FileBreadcrumbs
                fileTypeId={queryState.data.myFile.fileTypeId}
                title={queryState.data.myFile.name}
              />
            </Grid>
          </Grid>
          <Divider className="divider" />
          <FormlyForm
            formId={queryState.data.myFile.form.id}
            mode="manage"
            config={buildFormlyConfig(queryState.data.myFile.form.template)}
            model={buildModel(
              queryState.data.myFile.name,
              queryState.data.myFile.form.formDataJson,
            )}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            submitting={mutationState.loading}
          />
        </>
      )}
    </div>
  );
}
