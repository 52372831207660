/* eslint-disable import/prefer-default-export */
import { IAssignee } from 'formly/components/Assignees';
import { TaskFragment } from 'tillr-graphql';

export function formatTaskAssignees(task: TaskFragment) {
  const assignees: IAssignee[] = task.assignedToUsers
    .map((x) => ({ id: x.id, name: x.name, type: 'User' } as IAssignee))
    .concat(
      task.assignedToTeams.map((x) => ({ id: x.id, name: x.name, type: 'Team' } as IAssignee)),
    );

  return { ...task, assignees };
}

