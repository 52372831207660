import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { IModel } from 'formly/IModel';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FilterOperation, FormFilterBy, FormFilterFieldValuesType } from 'tillr-graphql';
import { IFilter } from './FilterControl';

interface IProps {
  filterFields: Array<FormFilterFieldValuesType>;
  onChange: (filters: Array<IFilter<FormFilterBy>>) => void;
  loading: boolean;
}

export function FilterFieldsControl(props: IProps) {
  const { filterFields, onChange } = props;

  const [model, setModel] = useState<IModel>({});

  useEffect(() => {
    onChange(
      Object.entries(model)
        .filter(([_, value]) => Boolean(value))
        .map(([fieldId, value]) => ({
          filterBy: FormFilterBy.Field,
          operation: FilterOperation.Equals,
          argument: JSON.stringify({ [fieldId]: value }),
        })),
    );
    // eslint-disable-next-line
  }, [model]);

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;

    if (!name) {
      return;
    }

    setModel((prevModel) => ({ ...prevModel, [name]: value ?? '' }));
  };

  return (
    <Grid container spacing={1} direction="row">
      {filterFields.map(({ fieldId, label, options }) => (
        <Grid key={fieldId} item>
          <FormControl variant="outlined" size="small">
            <InputLabel shrink id={fieldId}>
              {label}
            </InputLabel>
            <Select
              label={label}
              labelId={fieldId}
              input={<OutlinedInput notched label={label} />}
              name={fieldId}
              value={model[fieldId] ?? ''}
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value="">– Show all –</MenuItem>
              {options.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
}
