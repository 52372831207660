import { useGetAttachmentDetail } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { DownloadAttachmentControl } from 'components/Attachments/DownloadAttachmentControl';
import { Detail } from 'components/DataViews/Detail';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';

export function FileAttachment(props: RouteComponentProps<{ attachmentId: string }>) {
  const {
    match: {
      params: { attachmentId },
    },
  } = props;

  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetAttachmentDetail({ module, id: attachmentId });

  return (
    <div>
      <h2>Attachment</h2>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data &&
        (data.attachment ? (
          <>
            <DownloadAttachmentControl
              downloadAttachmentUrlTemplate={data.downloadAttachmentUrlTemplate!}
              attachment={data.attachment}
            />
            <Detail data={data.attachment} />
          </>
        ) : (
          !error && (
            <p>
              <i>Not found</i>
            </p>
          )
        ))}
    </div>
  );
}
