import React from 'react';
import { Module } from 'tillr-graphql';

interface IContext {
  module: Module;
  setModule: (module: Module) => void;
}

const ModuleContext = React.createContext<IContext | undefined>(undefined);

export { ModuleContext };
