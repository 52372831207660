import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useDetectTenantType } from 'components/Custom/useDetectTenantType';
import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import { IAppTheme } from 'themes/useGetTheme';

const useStyles = makeStyles((theme: IAppTheme) =>
  createStyles({
    root: {
      margin: 0,
      position: 'fixed',
      left: 0,
      top: 0,
      width: '240px',
      height: '64px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      '& img': {
        width: theme.siteTitle?.width,
        display: 'block',
      },
    },
  }),
);

export function SiteTitle() {
  const classes = useStyles();
  let siteName = 'Tillr';
  let siteLogo = 'tillr';
  const [tenantType] = useDetectTenantType();

  if (tenantType === 'squirrel') {
    siteName = 'VetPartners Squirrel';
    siteLogo = 'squirrel';
  }
  if (tenantType === 'systemconcepts') {
    siteName = 'System Concepts';
    siteLogo = 'systemconcepts';
  }

  return (
    <h1 className={classes.root}>
      <Tooltip title={`Return to ${siteName} home`}>
        <Link to="/">
          <img src={`/images/${siteLogo}.svg`} alt={siteName} />
        </Link>
      </Tooltip>
    </h1>
  );
}
