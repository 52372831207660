import Typography from '@material-ui/core/Typography';
import { useGetTeams } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { DataTable } from 'components/DataViews/DataTable';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import React, { useContext } from 'react';

export function Teams() {
  const { module } = useContext(ModuleContext)!;
  const { loading, error, data } = useGetTeams({ module });

  return (
    <div>
      <Typography variant="h2" noWrap>
        Teams
      </Typography>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.teams && <DataTable data={data?.teams} itemRoute="teams/{id}" />}
    </div>
  );
}
