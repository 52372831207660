import { useRouteMatch } from 'react-router-dom';
import { Module } from 'tillr-graphql';

function getRouteParam(module: Module) {
  switch (module) {
    case Module.TillrPlatform:
      return '-';
    default:
      return module.toLowerCase().replace(/_/g, '-');
  }
}

const routeParamModules = new Map<string, Module>(
  Object.values(Module).map((x) => [getRouteParam(x), x]),
);
export function getModuleFromRouteParam(routeParam: string) {
  return routeParamModules.get(routeParam);
}

export function getSiteModuleRoute(siteId: number | string, module: Module | string, path: string) {
  const moduleRouteParam = Object.values(Module).includes(module as Module)
    ? getRouteParam(module as Module)
    : module;
  const insertSlash = path && !path.startsWith('/');
  return `/site-${siteId}/${moduleRouteParam}${insertSlash ? '/' : ''}${path}`;
}

export function useSiteModuleRoute(path: string) {
  const {
    params: { siteId, module },
  } = useRouteMatch<{ siteId: string; module: string }>();
  return getSiteModuleRoute(siteId, module, path);
}

