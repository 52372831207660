import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  to: string;
  'aria-label'?: string;
  startIcon?: React.ReactNode;
  tooltip?: string;
  disabled?: boolean;
}

export function LinkButton(props: React.PropsWithChildren<IProps>) {
  const { to, 'aria-label': ariaLabel, startIcon, tooltip, disabled, children } = props;

  const button = (
    <Button
      className="no-wrap"
      color="secondary"
      variant="contained"
      component={Link}
      to={to}
      aria-label={ariaLabel}
      startIcon={startIcon}
      disabled={disabled}
    >
      {children}
    </Button>
  );

  // <span> is required for disabled buttons
  return tooltip ? (
    <Tooltip title={tooltip}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}
