import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ProgressSkeleton } from 'components/Shared/ProgressSkeleton';
import React from 'react';

export function LoadingFormTemplates() {
  function Row() {
    return (
      <TableRow>
        <TableCell>
          <div className="like-list-item-avatar">
            <ProgressSkeleton variant="circle" width={40} />
            <Box className="like-list-item-avatar__text like-list-item-avatar__text--restricted">
              <Typography variant="body1" display="block">
                <ProgressSkeleton variant="text" />
              </Typography>
            </Box>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2">Name</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Row />
          <Row />
          <Row />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
