import { Divider } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { NetworkStatusContext } from 'NetworkStatusContext';
import { UserProfileContext } from 'UserProfileContext';
import { useCreateForm } from 'apollo-hooks';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { Alert } from 'components/Shared/Alert';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { LinkButton } from 'components/Shared/LinkButton';
import { ProgressBar } from 'components/Shared/ProgressBar';
import { deleteOfflineForm, useGetOfflineForm, useLocalStorage } from 'db';
import { FormlyForm } from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import { HelpText } from 'formly/components/shared/HelpText';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Link as RouterLink, useHistory } from 'react-router-dom';
import { CreateFormInputType, CreateFormMutationVariables } from 'tillr-graphql';

export function OfflineForm(props: RouteComponentProps<{ formId: string }>) {
  const {
    match: {
      params: { formId },
    },
  } = props;
  const userProfile = useContext(UserProfileContext)!;
  const data = useGetOfflineForm(userProfile, formId);
  let loading = true;
  const [createForm, mutationState] = useCreateForm();
  const [instanceName, setInstanceName] = useState('');
  const [existingInstanceName, setExistingInstanceName] = useState('');
  const history = useHistory();
  const { savedOffline, editOfflineForm } = useLocalStorage(userProfile);
  const networkStatusContext = useContext(NetworkStatusContext);

  if (data) {
    loading = false;
  }

  const handleSubmit = (model: IModel) => {
    let modelProp = model;
    let createTasksProp = false;

    if (typeof model.model !== 'undefined' && typeof model.createTasks !== 'undefined') {
      modelProp = model.model;
      createTasksProp = model.createTasks;
    }

    const form: CreateFormInputType = {
      id: formId,
      templateId: data.form.templateId,
      name: instanceName,
      formDataJson: JSON.stringify(modelProp),
      isSubmitted: false,
    };

    const variables: CreateFormMutationVariables = {
      siteId: data.siteId,
      module: data.module,
      form,
      createTasks: createTasksProp,
    };

    if (networkStatusContext) {
      createForm({ variables });
    } else {
      editOfflineForm({
        ...variables,
        template: data?.form?.template,
        activeFormTemplateId: data?.form?.activeFormTemplateId,
        templateName: data?.form?.templateName,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstanceName(event.currentTarget.value);
  };

  const handleBlur = (event: any) => {
    if (!event.currentTarget.value.length) {
      setInstanceName(existingInstanceName);
    }
  };

  // TODO: Review - This was NOT how it was done originally
  // but there is a package on UAT (and perhaps prod) that
  // is stopping the original, better way from working
  const customFunction = (identifier: string, value: string) => {
    switch (identifier) {
      default: // editNameViaForm
        if (value.length) {
          setInstanceName(value);
        }
    }
  };

  useEffect(() => {
    if (data?.form?.name) {
      setInstanceName(data.form.name);
      setExistingInstanceName(data.form.name);
    }
  }, [data, setInstanceName, setExistingInstanceName]);

  const handleCancel = () => {
    history.push('/offline-forms');
  };

  if (mutationState.data?.createForm) {
    deleteOfflineForm(userProfile, data.id);
    return <Redirect to="/offline-forms" />;
  }

  if (savedOffline) {
    return <Redirect to="/offline-forms" />;
  }

  return (
    <>
      {loading && <ProgressBar />}
      {!loading &&
        (data && data.form ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2" noWrap display="block">
                  {data.form.name}
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" component={RouterLink} to="/">
                    Dashboard
                  </Link>
                  <Link color="inherit" component={RouterLink} to="/offline-forms">
                    Offline forms
                  </Link>
                  <Typography color="textPrimary">{data.form.name}</Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
            <HorizontalLine margin={[2, 0, 2, 0]} />
            <Alert type="info">
              <Typography variant="body1" display="block">
                The following offline form is saved to this device. It is strongly
                {'  recommended that you save this form to the'}
                {` ${userProfile.tenantName} system as soon as you have a connection to the internet. `}
              </Typography>
            </Alert>
            <ActionButtons>
              <LinkButton
                to="/offline-forms"
                aria-label="cancel"
                startIcon={<CloseIcon />}
                tooltip="Cancel"
              >
                Cancel
              </LinkButton>
            </ActionButtons>

            <Divider className="divider" />
            <Card>
              <CardContent>
                <form noValidate autoComplete="off">
                  <fieldset>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} className="tillr-form-grid-element">
                        <Typography variant="h5" gutterBottom>
                          Form name
                        </Typography>
                        <TextField
                          id="name"
                          value={instanceName}
                          variant="outlined"
                          onChange={handleChange}
                          required
                          fullWidth
                          onBlur={handleBlur}
                        />
                        <HelpText value="Edit this value to give your form a more identifiable name" />
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </CardContent>
            </Card>
            <Divider className="divider" />
            <FormlyForm
              formId={data.form.id}
              mode="manage"
              config={JSON.parse(data.form.template)}
              model={JSON.parse(data.form.formDataJson)}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              submitting={mutationState.loading}
              customFunctions={[customFunction]}
            />
            {/* <FormlyForm
            formId={data.form.id}
            customFunctions={[customFunction]}
            mode="manage"
            config={JSON.parse(data.form.template)}
            model={JSON.parse(data.form.formDataJson)}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            submitting={mutationState.loading}
          /> */}
          </>
        ) : (
          <p>
            <i>Not found</i>
          </p>
        ))}
    </>
  );
}

/*
{error && <ErrorDisplay error={error} />}
      {data && (data.form ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2" noWrap display="block">
                {data.form.name}
              </Typography>
              <FormBreadcrumbs
                title={data.form.name}
                subtitle="Form Instances"
                sublink={`${data.form.formTemplate.activeFormTemplateId}/forms`}
              />
            </Grid>
          </Grid>
          <HorizontalLine margin={[2, 0, 2, 0]} />
          <ActionButtons permission={editPermissions}>
            <DuplicateForm instance={data.form} />
            <ModuleLinkButton
              to={`/forms/${formId}/edit`}
              aria-label="edit"
              startIcon={<EditIcon />}
              tooltip="Edit form"
            >
              Edit
            </ModuleLinkButton>
          </ActionButtons>
          <FormlyForm
            formId={data.form.id}
            mode="readonly"
            config={JSON.parse(data.form.template)}
            model={JSON.parse(data.form.formDataJson)}
          />
        </>
      ) : !error && <p><i>Not found</i></p>)}
      */
