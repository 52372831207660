import { ApolloError } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useDeleteLibraryFolder } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ProgressBar } from 'components/Shared/ProgressBar';
import React, { useState } from 'react';
import { DeleteLibraryFolderMutationVariables } from 'tillr-graphql';
import { extractErrors } from 'utils';

interface IProps {
  folder: { id: string; name: string };
  parentFolderId: string;
  onDeleted?: (folderId: string) => void;
}

export function DeleteFolderControl(props: IProps) {
  const { folder, parentFolderId, onDeleted } = props;
  const [open, setOpen] = useState(false);

  const [deleteLibraryFolder, { loading, error, data, reset }] = useDeleteLibraryFolder({
    folderId: parentFolderId,
  });

  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const variables: DeleteLibraryFolderMutationVariables = { folderId: folder.id };
    deleteLibraryFolder({ variables });
  };

  const handleClickOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  };

  if (data?.deleteLibraryFolder) {
    if (onDeleted) {
      onDeleted(data.deleteLibraryFolder.id);
    }
    setOpen(false);
    reset();
  }

  function DeletionError(params: { errorMsg: ApolloError; folderName: string }) {
    const { errorMsg, folderName } = params;
    const errors = extractErrors(errorMsg);
    if (!errors?.length) {
      return null;
    }
    if (errors[0].match(/not empty/)) {
      return (
        <Typography variant="body1" display="block">
          {`Sorry, we are unable to delete '${folderName}' as it is not empty. Please delete it's contents first, then delete the folder.`}
        </Typography>
      );
    }
    return <ErrorDisplay error={errorMsg} />;
  }

  return (
    <>
      <Tooltip title={`Delete '${folder.name}`}>
        <IconButton className="action-button" onClick={handleClickOpen}>
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">Remove folder</DialogTitle>
        <DialogContent>
          {loading && <ProgressBar />}
          {error && <DeletionError errorMsg={error} folderName={folder.name} />}
          {!loading && !error && (
            <Typography variant="body1" display="block">
              {`Are you certain you want to remove the folder '${folder.name}'? Please note that only empty folders can be removed.`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {!error && (
            <>
              <Button onClick={handleClose} disabled={loading} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} disabled={loading} color="primary">
                {`Yes, remove '${folder.name}'`}
              </Button>
            </>
          )}
          {error && (
            <Button onClick={handleClose} disabled={loading} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
