export function filterBySiteId<
  T extends {
    includeSiteIds?: Array<number>;
    excludeSiteIds?: Array<number>;
  },
>(items: Array<T>, siteId: number): Array<T> {
  return items.filter(
    (item) =>
      (!item.includeSiteIds || item.includeSiteIds.includes(siteId)) &&
      (!item.excludeSiteIds || !item.excludeSiteIds.includes(siteId)),
  );
}

