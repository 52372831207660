import { Card, CardContent, Grid, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { useGetUserAdmin } from 'apollo-hooks';
import { AdminBreadcrumbs } from 'components/Admin/AdminBreadcrumbs';
import { AdminNavigation } from 'components/Admin/AdminNavigation';
import { SiteMultiCheckboxes } from 'components/Admin/Sites/SiteMultiCheckboxes';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { LinkButton } from 'components/Shared/LinkButton';
import { ProgressBar } from 'components/Shared/ProgressBar';
import { TitlePrefix } from 'components/Shared/TitlePrefix';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navMargin: {
      margin: theme.spacing(2, 0),
    },
  }),
);

interface IUserModel {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
}

export function User(props: RouteComponentProps<{ userId: string }>) {
  const {
    match: {
      params: { userId },
    },
  } = props;
  const [siteIds, setSiteIds] = useState<number[]>([]);
  const { loading, error, data } = useGetUserAdmin({ id: userId });
  const classes = useStyles();

  useEffect(() => {
    if (data?.userAdmin) {
      setSiteIds(data.userAdmin.sites.map((x) => x.id));
    }
  }, [data]);

  function getUserName() {
    let user = 'User';
    if (data && data.userAdmin) {
      user = `${data.userAdmin.firstName} ${data.userAdmin.lastName}`;
    }
    return user;
  }

  const [model, setModel] = useState<IUserModel>({});

  useEffect(() => {
    const user = {
      firstName: data?.userAdmin?.firstName,
      lastName: data?.userAdmin?.lastName,
      email: data?.userAdmin?.email,
      role: data?.userAdmin?.role.name,
    };
    setModel(user);
  }, [data, setModel]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <>
              <TitlePrefix color="primary">ADMIN</TitlePrefix>
              {getUserName()}
            </>
          </Typography>
          <AdminBreadcrumbs subtitle="Users" sublink="users" title={getUserName()} />
        </Grid>
        <Grid item xs={12} sm={4} />
      </Grid>
      <HorizontalLine margin={[2, 0, 2, 0]} />

      <AdminNavigation path="/admin/users" />

      <div className={classes.navMargin}>
        <ActionButtons>
          <LinkButton
            to={`/admin/users/${userId}/edit`}
            startIcon={<EditIcon />}
            aria-label="edit-user"
            tooltip="Edit this user"
          >
            Edit user
          </LinkButton>
        </ActionButtons>
      </div>

      {loading && <ProgressBar />}
      {error && <ErrorDisplay error={error} />}
      {model && model?.firstName && (
        <Card>
          <CardContent>
            <form>
              <fieldset>
                <Grid container spacing={3}>
                  <Grid item xs={12} className="tillr-form-grid-element">
                    <TextField
                      id="first-name"
                      label="First name"
                      value={model.firstName}
                      variant="outlined"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: !!(model.firstName as string)?.length,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="tillr-form-grid-element">
                    <TextField
                      id="last-name"
                      label="Last name"
                      value={model.lastName}
                      variant="outlined"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: !!(model.lastName as string)?.length,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="tillr-form-grid-element">
                    <TextField
                      id="email"
                      label="Email address"
                      value={model.email}
                      variant="outlined"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: !!(model.email as string)?.length,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="tillr-form-grid-element">
                    <TextField
                      id="role"
                      label="Role"
                      value={model.role}
                      variant="outlined"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: !!(model.role as string)?.length,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <HorizontalLine margin={[0, 0, 1, 0]} />
                    <Typography variant="h6">
                      {`${getUserName()} `}
                      has access to the following sites:
                    </Typography>
                  </Grid>
                </Grid>
                <SiteMultiCheckboxes readonly siteIds={siteIds} onChange={setSiteIds} />
              </fieldset>
            </form>
          </CardContent>
        </Card>
      )}
    </>
  );
}
