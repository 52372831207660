import { addMonths } from 'date-fns';
import React from 'react';
import { CalendarReport } from './CalendarReport';
import { ReportWidget } from './ReportWidget';
import { TasksStatusForSite } from './TasksStatusForSite';

interface IProps {
  type: string;
  props?: { [name: string]: any };
}

function convertInputParameters(inputParameters: any | undefined): any | undefined {
  if (inputParameters === undefined) {
    return undefined;
  }

  const entries = Object.entries(inputParameters);
  return Object.fromEntries(
    entries.map(([key, value]) => {
      if (value === 'TODAY') {
        return [key, new Date().toDateString()];
      }
      if (value === 'TODAY-1m') {
        return [key, addMonths(new Date(), -1).toDateString()];
      }
      return [key, value];
    }),
  );
}

export function Widget({ type, props }: IProps) {
  switch (type) {
    case 'CalendarReport': {
      if (!props) {
        console.warn(`Widget props not found: ${type}`);
        return null;
      }
      const { label, reportId, dateColumn, detailColumn, dayColor, groupByColumn, groupByColors } =
        props;
      return (
        <CalendarReport
          label={label}
          reportId={reportId}
          dateColumn={dateColumn}
          detailColumn={detailColumn}
          dayColor={dayColor}
          groupByColumn={groupByColumn}
          groupByColors={groupByColors}
        />
      );
    }
    case 'ReportWidget':
      return (
        <ReportWidget
          reportId={props?.reportId}
          viewIndex={props?.viewIndex}
          inputParameters={convertInputParameters(props?.inputParameters)}
        />
      );
    case 'TasksStatusForSite':
      return <TasksStatusForSite siteId={props?.siteId} />;
    default: {
      // eslint-disable-next-line no-console
      console.warn(`Widget not found: ${type}`);
      return null;
    }
  }
}
