import { Box, TableCell, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ProgressSkeleton } from 'components/Shared/ProgressSkeleton';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellText: {
      paddingTop: theme.spacing(1.5),
    },
  }),
);

interface IProps {
  parent?: number | null;
  results: boolean;
}

export function LoadingLibrary(props: IProps) {
  const { parent, results } = props;
  const classes = useStyles();
  return (
    <>
      {parent && (
        <TableRow>
          <TableCell>
            <div className="like-list-item-avatar">
              <ProgressSkeleton variant="circle" width={40} />
              <Box className="like-list-item-avatar__text like-list-item-avatar__text--restricted">
                <Typography variant="body1" display="block">
                  <ProgressSkeleton variant="text" />
                </Typography>
              </Box>
            </div>
          </TableCell>
          <TableCell colSpan={2} />
        </TableRow>
      )}
      {!results && (
        <TableRow>
          <TableCell>
            <div className="like-list-item-avatar">
              <ProgressSkeleton variant="circle" width={40} />
              <Box className="like-list-item-avatar__text like-list-item-avatar__text--restricted">
                <Typography variant="body1" display="block">
                  <ProgressSkeleton variant="text" />
                </Typography>
              </Box>
            </div>
          </TableCell>
          <TableCell>
            <Typography className={classes.tableCellText} variant="body1">
              <ProgressSkeleton width={20} />
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.tableCellText} variant="body1">
              <ProgressSkeleton />
            </Typography>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell>
          <div className="like-list-item-avatar">
            <ProgressSkeleton variant="rect" width={26} height={26} className="libraryIcon" />
            <Box className="like-list-item-avatar__text like-list-item-avatar__text--restricted">
              <Typography variant="body1" display="block">
                <ProgressSkeleton variant="text" />
              </Typography>
            </Box>
          </div>
        </TableCell>
        <TableCell>
          <ProgressSkeleton variant="tag" repeat={2} />
        </TableCell>
        <TableCell>
          <Typography className={classes.tableCellText} variant="body1">
            <ProgressSkeleton />
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
