import { Assignees } from './components/Assignees';
import { Attachments } from './components/Attachments';
// eslint-disable-next-line import/no-cycle
import { AutomatedTasks } from './components/AutomatedTasks';
import { Copy } from './components/Copy';
import { DashboardDocumentsCheckbox } from './components/DashboardDocumentsCheckbox';
import { DatePicker } from './components/DatePicker';
import { DueDate } from './components/DueDate';
import { Heading } from './components/Heading';
import { Hidden } from './components/Hidden';
import { Hr } from './components/Hr';
import { Input } from './components/Input';
import { MultiCheckboxes } from './components/MultiCheckboxes';
import { Number } from './components/Number';
import { PickList } from './components/PickList';
import { RadioButton } from './components/RadioButton';
import { ScoredCheckboxes } from './components/ScoredCheckboxes';
import { Signature } from './components/Signature';
import { SingleCheckbox } from './components/SingleCheckbox';
import { TextArea } from './components/TextArea';
import { TimePicker } from './components/TimePicker';
import { TodoField } from './components/TodoField';
import { IComponentProps } from './IComponentProps';

const fieldTypes: Map<string, (props: IComponentProps) => JSX.Element | null> = new Map([
  ['assignees', Assignees],
  ['attachments', Attachments],
  ['automatedtasks', AutomatedTasks],
  ['copy', Copy],
  ['dashboardDocumentsCheckbox', DashboardDocumentsCheckbox],
  ['datepicker', DatePicker],
  ['duedate', DueDate],
  ['heading', Heading],
  ['hidden', Hidden],
  ['hr', Hr],
  ['input', Input],
  ['number', Number],
  ['radio', RadioButton],
  ['checkbox', SingleCheckbox],
  ['signature', Signature],
  ['textarea', TextArea],
  ['tlrMultiCheckbox', MultiCheckboxes],
  ['tlrScoredCheckboxes', ScoredCheckboxes],
  ['tlrSelect', PickList],
  ['timepicker', TimePicker],
]);

export function getFieldTypeComponent(type: string) {
  const component = fieldTypes.get(type);
  if (component) {
    return component;
  }
  // eslint-disable-next-line no-console
  console.warn(`FORMLY TODO: handle field type "${type}".`);
  return TodoField;
}
