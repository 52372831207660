import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import differenceInDays from 'date-fns/differenceInDays';
import getDate from 'date-fns/getDate';
import getHours from 'date-fns/getHours';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import setMinutes from 'date-fns/setMinutes';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overdue: {
      backgroundColor: theme.palette.error.main,
      color: '#ffffff',
      '& .MuiChip-icon': {
        color: '#ffffff',
      },
    },
    due: {
      backgroundColor: theme.palette.success.main,
      color: '#ffffff',
      '& .MuiChip-icon': {
        color: '#ffffff',
      },
    },
    soon: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.text.primary,
      '& .MuiChip-icon': {
        color: theme.palette.text.primary,
      },
    },
  }),
);

interface IProps {
  date: Date;
  open: boolean;
  completedDate: string | null;
}

export function DaysRemaining(props: IProps) {
  const classes = useStyles();
  const { date, open, completedDate } = props;

  const leftDate = new Date(date);
  let rightDate = new Date();
  /* eslint-disable-next-line max-len */
  rightDate = setMinutes(
    new Date(getYear(rightDate), getMonth(rightDate), getDate(rightDate), getHours(leftDate), 0, 0),
    0,
  );

  const difference = differenceInDays(leftDate, rightDate);

  let label = `${difference} days`;

  let labelClass = classes.due;
  if (difference < 0) {
    labelClass = classes.overdue;
  }
  if (difference >= 0 && difference <= 2) {
    labelClass = classes.soon;
  }

  if (difference === 0) {
    label = 'Today';
  }

  if (difference === 1) {
    label = 'Tomorrow';
  }

  if (difference === -1) {
    label = 'Yesterday';
  }

  // If the task is closed, show 'Closed' and red
  // if it was completed after it was due, green if early or on time
  if (!open && completedDate) {
    const completedDiff = differenceInDays(leftDate, new Date(completedDate));
    label = 'Closed';
    labelClass = classes.due;
    if (completedDiff < 0) {
      labelClass = classes.overdue;
    }
  }

  return (
    <>
      <Chip icon={<AvTimerIcon />} label={label} className={labelClass} />
    </>
  );
}

