import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { ProgressButton } from 'components/Shared/ProgressButton';
import React from 'react';

interface IProps {
  label: string;
  submitting?: boolean;
  isValid: boolean;
  onSubmit: () => void;
}

export function SubmitButton(props: IProps) {
  const { label, submitting, isValid, onSubmit } = props;

  const [open, setOpen] = React.useState(false);

  const handleSubmit = () => {
    onSubmit();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ProgressButton label={label} disabled={submitting} isValid={isValid} onClick={handleOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Submit form?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once submitted, the form will no longer be editable.
          </DialogContentText>

          <FormsButtons>
            <CancelButton label="Cancel" onClick={handleClose} />
            <ProgressButton label={label} loading={submitting} onClick={handleSubmit} />
          </FormsButtons>
        </DialogContent>
      </Dialog>
    </>
  );
}

