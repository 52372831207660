import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useGetMySites } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import React, { useContext, useState } from 'react';
import { SiteContext } from './SiteContext';
import { SitesDialog } from './SitesDialog';

export function SiteControl2() {
  const siteContext = useContext(SiteContext);
  const [open, setOpen] = useState(false);
  const { loading, error, data } = useGetMySites();

  if (!siteContext) {
    return null;
  }

  const { siteId, setSiteId } = siteContext;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const site = data?.mySites ? data.mySites.find((x) => x.id === siteId) : null;

  return (
    <>
      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.mySites && site && (
        <>
          <Typography variant="h2">Current site:</Typography>
          <Typography variant="h2" style={{ fontWeight: 'bold' }} gutterBottom>
            {site.name}
          </Typography>
          {data.mySites.length > 1 && (
            <Button variant="outlined" onClick={handleClick}>
              Switch site
            </Button>
          )}

          <SitesDialog
            open={open}
            sites={data.mySites}
            onChange={setSiteId}
            onClose={handleClose}
          />
        </>
      )}
    </>
  );
}
