import { ApolloError } from '@apollo/client';
import { useGetMyReport } from 'apollo-hooks';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { useContext, useEffect, useState } from 'react';
import { ReportIdType, useRunReport } from './useRunReport';
import { parseData } from './utils';

interface IRunReportState {
  loading: boolean;
  data: Record<string, any>[] | undefined;
  error: string | ApolloError | null;
}

export function useRunReportToJson(
  reportId: string,
): [(inputParameters: Record<string, string>) => void, IRunReportState] {
  const { module } = useContext(ModuleContext)!;

  const getMyReportState = useGetMyReport({ module, id: reportId });
  const [runReport, runReportState] = useRunReport();

  const [jsonData, setJsonData] = useState<Record<string, any>[]>();

  useEffect(() => {
    if (getMyReportState.data?.myReport?.queryDefinition?.resultColumns && runReportState.data) {
      setJsonData(
        parseData(runReportState.data, getMyReportState.data.myReport.queryDefinition.resultColumns)
          .data,
      );
    }
  }, [getMyReportState.data, runReportState.data]);

  const runReportToJson = (inputParameters: Record<string, string>) => {
    runReport(reportId, ReportIdType.Report, inputParameters);
  };

  const state: IRunReportState = {
    loading: getMyReportState.loading || runReportState.loading,
    data: jsonData,
    error: getMyReportState.error || runReportState.error || null,
  };

  return [runReportToJson, state];
}
