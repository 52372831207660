import { createMuiTheme, lighten } from '@material-ui/core/styles';

// Used for user avatars and tags
export const miscColors = [
  {
    backgroundColor: '#494368', // Darkish Purpleish
    color: '#ffffff',
  },
  {
    backgroundColor: '#002E2C', // Darkish Greenish
    color: '#ffffff',
  },

  {
    backgroundColor: '#1B998B', // Lightish Greenish
    color: '#ffffff',
  },
  {
    backgroundColor: '#7C90A0', // Blueish Greyish
    color: '#ffffff',
  },
  {
    backgroundColor: '#EDE580', // Yellowish
    color: '#111111',
  },
  {
    backgroundColor: '#7A28CB', // Purpleish
    color: '#ffffff',
  },
  {
    backgroundColor: '#3581B8', // Blueish
    color: '#ffffff',
  },
  {
    backgroundColor: '#960200', // Redish
    color: '#ffffff',
  },
  {
    backgroundColor: '#12EAEA', // Turquoiseish
    color: '#111111',
  },
  {
    backgroundColor: '#FC60A8', // Pinkish
    color: '#ffffff',
  },
];

const siteCSSVars = {
  offlineMessage: {
    backgroundColor: '#232f3e',
    color: '#fff',
  },
  mandatory: {
    color: '#c80000',
  },
  disabledFormElements: {
    backgroundColor: '#f6f6f6',
    color: '#333',
  },
  assigneeSummary: {
    one: {
      backgroundColor: '#B1EDE8',
      color: '#111111',
    },
    two: {
      backgroundColor: '#6D435A',
      color: '#ffffff',
    },
  },
  siteTitle: {
    width: '80px',
  },
  libraryFileIcon: {
    color: '#33658a',
  },
  automatedTasks: {
    defaultColor: '#1c2430',
    colorLight: lighten('#1c2430', 0.9),
    colorDark: lighten('#1c2430', 0.8),
  },
  fixedFormButtons: {
    backgroundColor: '#f8f9fc',
  },
};

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#111111',
    },
    background: {
      default: '#f8f9fc',
      paper: '#fff',
    },
    primary: {
      main: '#fd7300',
    },
    secondary: {
      main: '#33658a',
    },
    error: {
      main: '#c80000',
    },
    warning: {
      main: '#fcf8e3',
      dark: '#c09853',
    },
    info: {
      main: '#d1ecf1',
      dark: '#bee5eb',
    },
    type: 'light',
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  typography: {
    fontFamily: [
      // '"Nunito"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
  },
});

theme.overrides = {
  MuiAccordion: {
    root: {
      width: '100%',
      margin: '12px',
      '&$expanded': {
        margin: '12px',
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      flexDirection: 'column',
    },
  },
  MuiAccordionSummary: {
    root: {
      backgroundColor: '#f6f6f6',
      '&$expanded': {
        backgroundColor: '#fff',
      },
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: 'rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;',
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  MuiButton: {
    root: {
      '&.themed-submit-button': {
        color: '#fff',
        fontWeight: '700',
      },
    },
  },
  MuiCheckbox: {
    colorSecondary: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      margin: '24px 0',
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: '#1c2430',
      '& h1': {
        backgroundColor: '#232f3e',
      },
      '& .MuiListItemText-root': {
        color: '#ffffff',
      },
      '& .MuiListItemIcon-root': {
        color: '#ffffff',
      },
      '& .MuiCollapse-wrapper': {
        backgroundColor: '#0a0b0c',
      },
      '& .nav--footer': {
        backgroundColor: '#232f3e',
      },
    },
  },
  MuiFormLabel: {
    root: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
    asterisk: {
      color: siteCSSVars.mandatory.color,
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },
  MuiFormControlLabel: {
    label: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        backgroundColor: siteCSSVars.disabledFormElements.backgroundColor,
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiRadio: {
    colorSecondary: {
      '&$disabled': {
        color: siteCSSVars.disabledFormElements.color,
      },
    },
  },
  MuiTab: {
    labelIcon: {
      minHeight: '48px',
    },
    wrapper: {
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      '& > :first-of-type': {
        marginBottom: '0',
        marginRight: theme.spacing(1),
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.9rem',
      backgroundColor: '#1c2531',
    },
    arrow: {
      color: '#1c2531',
    },
  },
};

const defaultTheme = { ...theme, ...siteCSSVars };

export { defaultTheme };
