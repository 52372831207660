import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import set from 'date-fns/set';
import { IComponentProps } from 'formly/IComponentProps';
import React, { useEffect } from 'react';
import { HelpText } from './shared/HelpText';
import {
  FieldValidationMessage,
  NestedFieldValidationMessage,
  getRuleMessage,
  hasFieldError,
} from './shared/Validation';

export function TimePicker(props: IComponentProps) {
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState,
  } = props;
  const value = modelValue || null;
  const formatStringAsTime = (v: string | null) => {
    if (v && v.split) {
      return v
        ? set(new Date(), { hours: Number(v.split(':')[0]), minutes: Number(v.split(':')[1]) })
        : null;
    }
    return v;
  };

  const customTrigger = () => {
    const values = getValues();
    if (field.templateOptions?.required && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  useEffect(() => {
    if (readonly) return;
    register({ name: field.key });
    setValue(field.key, value, { shouldDirty: false });
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  const handleChange = (date: Date | string | number | null) => {
    if (field.key) {
      let fDate = date || '';
      if (fDate) {
        fDate = new Date(fDate);
        fDate = `${getHours(fDate)}:${getMinutes(fDate)}`;
      }
      onValueUpdate(field.key, fDate);
      setValue(field.key, fDate, { shouldDirty: true });
      customTrigger();
    }
  };

  return (
    <>
      {field.className?.match(/clear/) && (
        <hr className="tillr-form-grid-element tillr-form-grid-element--break" />
      )}
      <Grid
        item
        xs={12}
        sm={field.className?.match(/col-xs-6/) ? 6 : 12}
        className={
          field.className?.match(/pull-right/)
            ? 'tillr-form-grid-element tillr-form-grid-element--align-right'
            : 'tillr-form-grid-element'
        }
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            id={field.key}
            inputVariant="outlined"
            label={field.templateOptions?.label}
            format="HH:mm"
            value={formatStringAsTime(value)}
            onChange={handleChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            keyboardIcon={
              <>
                {hasFieldError(field.key!, errors, formState) ? (
                  <ScheduleIcon color="error" />
                ) : (
                  <ScheduleIcon />
                )}
              </>
            }
            ampm={false}
            disabled={readonly || field.templateOptions?.disabled}
            clearable
            required={!readonly && field.templateOptions?.required}
            invalidDateMessage={
              <NestedFieldValidationMessage
                message="Invalid time format. Please use HH:MM."
                className="as-body1"
              />
            }
            error={hasFieldError(field.key!, errors, formState)}
          />
        </MuiPickersUtilsProvider>
        {hasFieldError(field.key!, errors, formState) && (
          <FieldValidationMessage message={errors[field.key!].message} />
        )}
        {field.data?.help && <HelpText value={field.data?.help} />}
      </Grid>
    </>
  );
}
