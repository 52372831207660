import { Checkbox, Typography } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { useGetSitesAdmin } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { SiteAdminType } from 'tillr-graphql';
import { LoadingSites } from './LoadingSites';
import { siteStyles } from './siteStyles';

interface ITreeProps {
  treeData: any;
}

interface IProps {
  siteIds: number[];
  onChange: (siteIds: number[]) => void;
  readonly: boolean;
}

interface StyledTreeItemProps {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  nodeId: string;
  children: React.ReactNode;
  checked: boolean;
  disabled: boolean;
}

const SiteMultiCheckboxes = memo(function SiteMultiCheckboxes(props: IProps) {
  const classes = siteStyles();
  const { siteIds, onChange, readonly } = props;
  const [expanded, setExpanded] = useState<string[]>([]);
  const { loading, error, data } = useGetSitesAdmin();
  let siteIdsForDisplay: number[] = [];

  useEffect(() => {
    let expandedArray: string[] = [];
    if (data?.sitesAdmin) {
      expandedArray = data.sitesAdmin.map((site: any) => site.id.toString());
    }
    setExpanded(expandedArray);
  }, [data, setExpanded]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { checked, value } = event.target;
    const siteId = Number(value);
    if (checked) {
      onChange(siteIds.concat(siteId));
    } else {
      onChange(siteIds.filter((x) => x !== siteId));
    }
  };

  function StyledTreeItem(itemProps: StyledTreeItemProps) {
    const {
      labelText,
      labelIcon: LabelIcon,
      labelInfo,
      color,
      bgColor,
      nodeId,
      checked,
      disabled,
      ...other
    } = itemProps;

    return (
      <TreeItem
        nodeId={nodeId}
        label={
          <div className={classes.labelRoot}>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              value={nodeId}
              disabled={disabled}
              classes={{
                disabled: classes.disabled,
              }}
            />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    );
  }

  const renderTree = (sites: SiteAdminType[], parentSiteId: number | null) => {
    let childSites = parentSiteId
      ? sites.filter((x) => x.parentSiteId === parentSiteId)
      : // All sites that don't have a parent also in the list
        sites.filter((x) => !sites.map((y) => y.id).includes(x.parentSiteId || 0));
    childSites = childSites.sort((a, b) => (a.name > b.name ? 1 : -1));

    function isChecked(x: any) {
      let result = false;

      // If the site itself is in the data, return true
      // eslint-disable-next-line react/prop-types
      if ((siteIds as number[]).includes(x.id)) {
        if (!siteIdsForDisplay.includes(x.id)) {
          siteIdsForDisplay = siteIdsForDisplay.concat([x.id]);
        }
        result = true;
      }

      // If the parent site is in the data, return true
      // eslint-disable-next-line react/prop-types
      if (!!x.parentSiteId && (siteIds as number[]).includes(x.parentSiteId)) {
        if (!siteIdsForDisplay.includes(x.parentSiteId)) {
          siteIdsForDisplay = siteIdsForDisplay.concat([x.parentSiteId]);
        }
        result = true;
      }

      // If the parent site is checked because of the logic (and not because
      // it's what the data says), return true
      if (!!x.parentSiteId && (siteIdsForDisplay as number[]).includes(x.parentSiteId)) {
        if (!siteIdsForDisplay.includes(x.id)) {
          siteIdsForDisplay = siteIdsForDisplay.concat([x.id]);
        }
        result = true;
      }

      return result;
    }

    function isDisabled(x: any) {
      let result = false;

      if (readonly) {
        return true;
      }

      // If the parent site is in the data, return true
      // eslint-disable-next-line react/prop-types
      if (!!x.parentSiteId && (siteIds as number[]).includes(x.parentSiteId)) {
        if (!siteIdsForDisplay.includes(x.parentSiteId)) {
          siteIdsForDisplay = siteIdsForDisplay.concat([x.parentSiteId]);
        }
        result = true;
      }

      // If the parent site is checked because of the logic (and not because
      // it's what the data says), return true
      if (!!x.parentSiteId && (siteIdsForDisplay as number[]).includes(x.parentSiteId)) {
        if (!siteIdsForDisplay.includes(x.id)) {
          siteIdsForDisplay = siteIdsForDisplay.concat([x.id]);
        }
        result = true;
      }

      return result;
    }

    if (childSites.length > 0) {
      return (
        <>
          {childSites.map((x) => (
            <StyledTreeItem
              nodeId={x.id.toString()}
              key={x.id}
              labelText={x.name}
              labelIcon={SupervisorAccountIcon}
              labelInfo="90"
              checked={isChecked(x)}
              disabled={isDisabled(x)}
            >
              {renderTree(sites, x.id)}
            </StyledTreeItem>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <>
      {loading && <LoadingSites />}
      {error && <ErrorDisplay error={error} />}
      {data && data.sitesAdmin && (
        <TreeView className={classes.root} expanded={expanded}>
          {renderTree(data.sitesAdmin, null)}
        </TreeView>
      )}
    </>
  );
});

export { SiteMultiCheckboxes };
