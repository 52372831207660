import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activity: {},
    tag: {
      display: 'inline-block',
      margin: theme.spacing(0.25),
      borderRadius: '16px',
      width: '70px',
      height: '32px',
    },
    libraryIcon: {
      margin: '7px',
    },
  }),
);

interface IProps {
  variant?: string;
  width?: string | number;
  height?: string | number;
  repeat?: number;
  className?: string;
}

export function ProgressSkeleton(props: IProps) {
  const classes = useStyles();
  // TODO: Got to be a better way
  const getClassName = (name: string) => classes[name as 'activity' | 'libraryIcon'];
  const {
    variant = 'text',
    width = '100%',
    height = 40,
    repeat = 1,
    className = 'activity',
  } = props;

  switch (variant) {
    case 'rect':
      // eslint-disable-next-line dot-notation
      return (
        <Skeleton
          className={getClassName(className)}
          variant="rect"
          height={height}
          width={width}
        />
      );
    case 'circle':
      return (
        <Skeleton
          className={getClassName(className)}
          variant="circle"
          height={height}
          width={width}
        />
      );
    case 'tag':
      return (
        <>
          {[...Array(repeat)].map((e: any, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Skeleton
              className={[getClassName(className), classes.tag].join(' ')}
              key={i}
              variant="rect"
            />
          ))}
        </>
      );
    default:
      return <Skeleton className={getClassName(className)} variant="text" width={width} />;
  }
}
