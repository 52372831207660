import Paper from '@material-ui/core/Paper';
// eslint-disable-next-line import/no-cycle
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { DataTable } from './DataTable';

interface IProps {
  data: any;
  itemRoutes?: string[];
  ignoreProps?: string[];
}

export function Detail(props: IProps) {
  const { data, itemRoutes, ignoreProps } = props;

  if (data == null) {
    return null;
  }
  if (Array.isArray(data)) {
    return <DataTable data={data} />;
  }
  if (typeof data !== 'object') {
    return data?.toString();
  }

  const keys = Object.keys(data).filter((k) => k !== '__typename');
  const renderRoute = (key: string, value: any) => {
    const keyPlaceholder = `{${key}}`;
    const itemRoute = itemRoutes?.filter((x) => x.includes(keyPlaceholder))[0];
    if (itemRoute) {
      return <Link to={itemRoute.replace(keyPlaceholder, value)}>{value}</Link>;
    }
    return null;
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="tenant table">
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map(
            (k) =>
              !ignoreProps?.includes(k) && (
                <TableRow key={k}>
                  <TableCell component="th" scope="row">
                    {k}
                  </TableCell>
                  <TableCell>{renderRoute(k, data[k]) || <Detail data={data[k]} />}</TableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
