import DateFnsUtils from '@date-io/date-fns';
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import addYears from 'date-fns/addYears';
import formatISO from 'date-fns/formatISO';
import isValid from 'date-fns/isValid';
import set from 'date-fns/set';
import { IComponentProps } from 'formly/IComponentProps';
import { withComponentPropsMemo } from 'formly/withComponentPropsMemo';
import React, { useEffect, useState } from 'react';
import { PICKERS_DATE_FORMAT } from 'utils';
import {
  FieldValidationMessage,
  getRuleMessage,
  NestedFieldValidationMessage,
} from './shared/Validation';

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    width: '100%',
  },
  label: {
    padding: theme.spacing(3, 0, 0, 0),
  },
  dueGrid: {
    marginTop: 0,
    '& .MuiFormControlLabel-root': {
      paddingTop: theme.spacing(1),
    },
  },
}));

const DueDate = withComponentPropsMemo(function DueDate(props: IComponentProps) {
  const classes = useStyles();
  const {
    field,
    readonly,
    modelValue,
    onValueUpdate,
    register,
    errors,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = props;
  // "undefined" defaults to now, "null" defaults to unset.
  const value = modelValue || null;
  const [dueDateType, setDueDateType] = useState<string>('on');

  const handleDueTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDueDateType(event.currentTarget.value);
  };

  const customTrigger = () => {
    const values = getValues();
    if (dueDateType === 'on' && field.key && !values[field.key]?.length) {
      setError(field.key, {
        type: 'manual',
        message: getRuleMessage('required'),
      });
      return;
    }
    clearErrors(field.key);
  };

  const dueTimes: string[] = ['days(s)', 'week(s)', 'month(s)', 'year(s)'];

  const [unitValue, setUnitValue] = useState<number>(1);
  const [timeValue, setTimeValue] = useState<string>(dueTimes[1]);

  useEffect(() => {
    register({ name: field.key });
    setValue(field.key, value);
    setUnitValue(1);
    setTimeValue(dueTimes[1]);
    customTrigger();
    // TODO: If I remove the empty array, useEffect keeps getting called. Isn't the
    // idea that an empty array will only call useEffect once?
    // eslint-disable-next-line
  }, []);

  const handleChange = (date: Date | string | null) => {
    if (field.key) {
      let fDate = date;
      if (fDate && isValid(fDate)) {
        fDate = formatISO(set(new Date(fDate), { hours: 0, minutes: 0, seconds: 0 }));
      }
      onValueUpdate(field.key, fDate);
      setValue(field.key, fDate);
      customTrigger();
    }
  };

  const calculateManualTimeChange = (unit: number, type: string) => {
    let date;
    switch (type) {
      case 'week(s)':
        date = addWeeks(new Date(), unit);
        break;
      case 'days(s)':
        date = addDays(new Date(), unit);
        break;
      case 'year(s)':
        date = addYears(new Date(), unit);
        break;
      default: // months. Don't ask why this is last, and not days or years!
        date = addMonths(new Date(), unit);
    }
    if (dueDateType === 'in') {
      handleChange(date);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (field.key && date && isValid(date)) {
        date = formatISO(set(new Date(date), { hours: 0, minutes: 0, seconds: 0 }));
        onValueUpdate(field.key, date);
      }
    }
  };

  useEffect(() => {
    if (dueDateType === 'in') {
      if (unitValue > 0 && dueTimes.includes(timeValue)) {
        calculateManualTimeChange(unitValue, timeValue);
      }
    }
    // eslint-disable-next-line
  }, [unitValue, timeValue, dueTimes]);

  const handleManualTimeChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const { name, value: selectValue } = event.target;
    if (name?.indexOf('units') !== -1) {
      setUnitValue(selectValue as number);
    }
    if (name?.indexOf('times') !== -1) {
      setTimeValue(selectValue as string);
    }
  };

  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="due-date-type"
          name="due-date-type"
          value={dueDateType}
          onChange={handleDueTypeChange}
        >
          <Grid container spacing={3} className={classes.dueGrid}>
            <Grid item xs={4}>
              <FormControlLabel
                value="on"
                control={<Radio />}
                labelPlacement="start"
                label="Task due on"
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id={field.key}
                  label={field.templateOptions?.label}
                  format={PICKERS_DATE_FORMAT}
                  value={value}
                  onChange={handleChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  keyboardIcon={
                    <>{errors[field.key!] ? <EventIcon color="error" /> : <EventIcon />}</>
                  }
                  minDate={new Date()}
                  maxDate={field.data?.maxDate}
                  disabled={readonly || dueDateType !== 'on' || field.templateOptions?.disabled}
                  required={!readonly && dueDateType === 'on'}
                  invalidDateMessage={
                    <NestedFieldValidationMessage
                      message="Invalid date format. Please use DD/MM/YYYY."
                      className="as-body1"
                    />
                  }
                  error={!!errors[field.key!]}
                />
              </MuiPickersUtilsProvider>
              {dueDateType === 'on' && errors[field.key!] && (
                <FieldValidationMessage message={errors[field.key!].message} />
              )}
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                value="in"
                control={<Radio />}
                labelPlacement="start"
                label="Task due in"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleManualTimeChange}
                select
                value={unitValue}
                id="due-date-units"
                disabled={dueDateType === 'on'}
                required={dueDateType === 'in'}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                label=""
                name="due-date-units"
              >
                {[...Array(12)].map((el, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={i + 1} value={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                onChange={handleManualTimeChange}
                select
                value={timeValue}
                id="due-date-times"
                disabled={dueDateType === 'on'}
                required={dueDateType === 'in'}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                label=""
                name="due-date-times"
              >
                {dueTimes.map((el, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={i + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </>
  );
});

export { DueDate };
