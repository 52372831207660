import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { LibraryBreadcrumbs } from './LibraryBreadcrumbs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    likeTextLink: {
      color: theme.palette.text.primary,
      minWidth: 'auto',
      padding: 0,
      textTransform: 'none',
      textDecoration: 'none',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: 'transparent',
        '& span': {
          color: theme.palette.primary.main,
        },
      },
      '& span': {
        cursor: 'pointer',
        padding: theme.spacing(0, 0.25),
        textDecoration: 'underline',
      },
    },
  }),
);

interface IProps {
  handleClear: () => void;
  searchData: any;
  data: any;
  query: string;
}

export function LibraryLocation(props: IProps) {
  const { handleClear, searchData, data, query } = props;
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" noWrap display="block">
        Current location:
      </Typography>
      {!!query.length && searchData && (
        <Typography color="textPrimary">
          Search results. Found
          {` ${searchData.libraryFiles.files.length} `}
          {searchData.libraryFiles.files.length === 1 ? ' file.' : ' files.'}
          {!!searchData.libraryFiles.files.length && (
            <Button className={classes.likeTextLink} onClick={handleClear}>
              <span>Return to folder view.</span>
            </Button>
          )}
        </Typography>
      )}
      {!query.length && (
        <LibraryBreadcrumbs
          folderId={data.libraryFolder.id}
          folderName={data.libraryFolder.name}
          ancestors={data.libraryFolder.ancestorFolders}
        />
      )}
    </>
  );
}

