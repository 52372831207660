import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCreateTask } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ModuleRedirect } from 'components/Modules/ModuleRedirect';
import { TitlePrefix } from 'components/Shared/TitlePrefix';
import { SiteContext } from 'components/Sites/SiteContext';
import { IAssignee } from 'formly/components/Assignees';
import { FormlyForm } from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import { getStaticTemplate } from 'formly/staticTemplates';
import React, { useContext, useState } from 'react';
import { AssigneeType, CreateTaskInputType, CreateTaskMutationVariables } from 'tillr-graphql';
import { generateUuid } from 'utils';
import { TasksBreadcrumbs } from './TasksBreadcrumbs';

export function AddTask() {
  const { siteId } = useContext(SiteContext)!;
  const { module } = useContext(ModuleContext)!;
  const [createTask, { loading, data, error }] = useCreateTask();
  const [taskId] = useState(generateUuid());

  if (data?.createTask) {
    return <ModuleRedirect to={`/tasks/${data.createTask.id}`} />;
  }

  const handleSubmit = (model: IModel) => {
    const task: CreateTaskInputType = {
      id: taskId,
      name: model.name || '',
      description: model.description || '',
      dueDate: model.dueDate,
      assignees: model.assignees.map((x: IAssignee) => ({
        assigneeId: x.id,
        assigneeType: x.type === 'User' ? AssigneeType.User : AssigneeType.Team,
      })),
    };

    const variables: CreateTaskMutationVariables = { siteId, module, task };
    createTask({ variables });
  };

  const config = getStaticTemplate('create-task');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography variant="h2" noWrap display="block">
            <TitlePrefix color="secondary">NEW</TitlePrefix>
            Task
          </Typography>
          <TasksBreadcrumbs title="New Task" />
        </Grid>
      </Grid>
      <Divider className="divider" />
      {error && <ErrorDisplay error={error} />}
      <FormlyForm
        formId={taskId}
        mode="create"
        config={config}
        onSubmit={handleSubmit}
        submitting={loading}
      />
    </>
  );
}
