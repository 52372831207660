import { Box, List, ListItem, Typography } from '@material-ui/core';
import { ModuleLinkButton } from 'components/Modules/ModuleLinkButton';
import { SiteContext } from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { filterBySiteId } from './utils';

export type QuickLinkProps = {
  label: string;
  type: string;
  args: Array<any>;
  includeSiteIds?: Array<number>;
  excludeSiteIds?: Array<number>;
};

function getPath({ type, args }: QuickLinkProps): string | null {
  switch (type) {
    case 'CreateForm': {
      return `/form-templates/${args[0]}/forms/add`;
    }
    case 'ViewForms': {
      return `/form-templates/${args[0]}/forms`;
    }
    default: {
      // TODO: log
      return null;
    }
  }
}

interface IProps {
  links: Array<QuickLinkProps>;
}

export function QuickLinks(props: IProps) {
  const { links } = props;

  const siteContext = useContext(SiteContext);

  if (!siteContext) {
    return null;
  }

  const linksToShow = filterBySiteId(links, siteContext.siteId);

  if (linksToShow.length === 0) {
    return null;
  }

  return (
    <Box mt={3}>
      <Typography variant="h2">Quick links</Typography>
      <List>
        {linksToShow.map((link, i) => {
          const path = getPath(link);
          return (
            path && (
              <ListItem key={i}>
                <ModuleLinkButton to={path}>{link.label}</ModuleLinkButton>
              </ListItem>
            )
          );
        })}
      </List>
    </Box>
  );
}
