import { IModel } from 'formly/IModel';
import React from 'react';
import { BarChartView } from './DataViews/BarChartView';
import { PieChartView } from './DataViews/PieChartView';
import { SunburstView } from './DataViews/SunburstView';
import { TableView } from './DataViews/TableView';
import { IReportConfig } from './IReportConfig';
import { DataColumn, parseData } from './utils';

interface IProps {
  inputParametersDefinition: DataColumn[];
  inputParameters: IModel;
  reportConfig: IReportConfig;
  viewIndex?: number;
  data: string[][];
  resultColumns: DataColumn[];
}

enum DataViewType {
  Bar,
  Pie,
  Sunburst,
  Table,
}

export function ReportDataView(props: IProps) {
  const {
    inputParametersDefinition,
    inputParameters,
    reportConfig,
    viewIndex = 0,
    data,
    resultColumns,
  } = props;

  const { pivot } = reportConfig;
  const parsedData = parseData(data, resultColumns, pivot);

  const view = reportConfig.views[viewIndex];
  const dataView = DataViewType[view.type as keyof typeof DataViewType];
  switch (dataView) {
    case DataViewType.Bar:
      return (
        <BarChartView
          inputParametersDefinition={inputParametersDefinition}
          inputParameters={inputParameters}
          columns={parsedData.columns}
          data={parsedData.data}
          groupByColumnName={view.groupBy!}
          isGroupByMonth={view.isGroupByMonth ?? false}
          countColumnName={view.countColumn}
        />
      );
    case DataViewType.Pie:
      return (
        <PieChartView
          columns={parsedData.columns}
          data={parsedData.data}
          groupByColumnName={view.groupBy!}
          countColumnName={view.countColumn}
        />
      );
    case DataViewType.Sunburst:
      return (
        <SunburstView
          columns={parsedData.columns}
          data={parsedData.data}
          groupByColumnName={view.groupBy!}
          thenGroupByColumnName={view.thenGroupBy!}
        />
      );
    case DataViewType.Table: {
      return (
        <TableView
          reportName={view.title}
          columns={parsedData.columns}
          data={parsedData.data}
          initialFilterOn={view.initialFilterOn}
        />
      );
    }
    default:
      throw new Error(`Unknown DataViewType: ${view.type}`);
  }
}
